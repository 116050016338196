import React from 'react';

import styled from 'styled-components';
import { API } from "aws-amplify";
import * as mutations from 'graphql/_mutations';
import * as queries from 'graphql/_queries';

const Actions = (props) => {

  const { mobil, status } = props;

  const [isSync, setIsSync] = React.useState(false);
  const [isReseting, setIsReseting] = React.useState(false);

  const handleSync = async () => {
    setIsSync(true);
    
    try {
      const data = { 
        headers: {}, 
        response: true,
      };

      const response = await API.post('laViaSanaREST', '/sync', data)
      console.log('SYNC: ', response.data);  
      setIsSync(false);

    } catch(error) {
      setIsSync(false);
      console.log('Error in sync, please contact support.', error);
      alert("Error in sync, please contact support.");
    };
  };

  const handleReset = async () => {
    setIsReseting(true);
    try {
      var res = await API.graphql({ 
        query: mutations.deleteLaViaSana, 
        variables: { input: { 
          id: 'validate',
          sk: 'PROGRESS'
        }}
      })

      var res = await API.graphql({ 
        query: mutations.deleteLaViaSana, 
        variables: { input: { 
          id: 'consolidate',
          sk: 'PROGRESS'
        }}
      })
      setIsReseting(false);
      props.handleReset();

    } catch(e) {
      console.log('Error reseting: ', e);
      setIsReseting(false);
    }
  };

  const ActionButton = () => {
    if ( status.isActive ) {
       return <Action>
        <Progress style={{ 
          width: status.progress.toString()+'%'
        }}/>
        <span>
          { status.status === 'validate' ?
            'Validando' : 'Consolidando' }
          {' '}
          {status.progress}%
        </span>
      </Action>
    } else {
      if ( status.status === 'validate' ) {
        return <button 
          className='button primary'
          name='validate' 
          onClick={props.handleValidation}
        >
          Validar
        </button> 
      } else {
        return <button 
          className='button primary'
          name='consolidate' 
          onClick={props.handleConsolidation}
        >
          Consolidar
        </button> 
      }
    }
  };

  return <Container>
    <ActionButton />

    {/*RESET*/}
    <Reset 
      active={ isReseting ? 1 : 0 } 
      onClick={handleReset}
    >
      { isReseting ? 
          <i className='fas fa-rotate fa-spin' /> : 
          'R' }
    </Reset>

    {/*SYNC*/}
    <Sync 
      active={ isSync ? 1 : 0 }
      onClick={handleSync}
    >
      { isSync ? 
          <i className='fas fa-rotate fa-spin' /> : 
          <i className='fas fa-rotate' />
      }
    </Sync>
  </Container>
};

const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  padding: 0px 20px;
  z-index: 2;

  display: grid;
  grid-template-columns: auto 40px 40px;
  align-items: center;
  grid-gap: 8px;

  border-top: 2px solid #C767E5;
  border-top: 2px solid #D0BDE2;

  & button {
    height: 40px;
  }

  @media (min-width: 666px) {
    left: 20px;
    width: calc(100% - 40px);
    height: auto;
    padding: 20px 0px;

    border: none;
  }

`;

const Reset = styled.button`
  transition-duration: 0.3s;
  cursor: pointer;
  width: 40px;

  outline: none;
  border: 2px solid gray;
  border-radius: 50%;
  background-color: gray;

  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Nunito', serif;

  &:hover {
    background-color: gray;
    color: #fff;
  }
  &:active {
    background-color: lightgray;
  }

  @media (min-width: 666px) {
    background-color: ${props => props.active ? 'gray' : 'transparent'};
    color: ${props => props.active ? '#fff' : 'gray'};
  }
`;

const Sync = styled.button`
  transition-duration: 0.3s;
  cursor: pointer;
  width: 40px;

  outline: none;
  border: 2px solid #C767E5;
  border-radius: 50%;
  background-color: #C767E5;

  color: #fff;
  font-size: 14px;

  &:hover {
    background-color: #C767E5;
    color: #fff;
  }
  &:active {
    background-color: #46005D;
    color: #fff;
  }

  @media (min-width: 666px) {
    background-color: ${props => props.active ? '#C767E5' : 'transparent'};
    color: ${props => props.active ? '#fff' : '#C767E5'};

    &:hover {
      background-color: #C767E5;
      color: #fff;
    }
    &:active {
      background-color: #46005D;
      color: #fff;
    }
  }
`;

const Action = styled.div`
  transition-duration: 0.3s;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  width: 90%;

  border: none;
  border: 3px solid #C767E5;
  outline: none;
  border-radius: 25px;
  background-color: rgb(250,250,250);

  white-space: nowrap;

  &:hover {
    border: 3px solid #C767E5;
    color: #C767E5;
    background-color: rgb(250,250,250);
  }

  & span {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #C767E5;
  }
`;

const Progress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  background-color: rgba(199,103,229,0.2);
`;

export default Actions;
