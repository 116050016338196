import React, { useState, useEffect, useRef } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import './SelectStatus.css';

var statuses = [
  {value: 'open', label: 'Abierta'},
  {value: 'converted', label: 'Convertida'},
  {value: 'validated', label: 'Validada'},
  {value: 'consolidated', label: 'Consolidada'}
]

function SelectStatus(props) {
  
  const { mobil } = props;

  const [isUp, setIsUp] = useState(true);
  const [cursor, setCursor] = useState(0);
  const [showList, setShowList] = useState(false);

  const wrapperRef = useRef(null);

  React.useEffect(() => {
    statuses.forEach((item, i) => {
      if ( item.value === props.value ) {
        setCursor(i);
        return true
      }
    })
  }, [])

  function useOutside(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // console.log(ref.current, event.target);
          setShowList(false);
        }
      }
      
      function onKeyDown(e) {
        if (e.key === 'Escape') {
          setShowList(false);
        } else if ( e.key === 'ArrowUp' ) {
          if ( showList && cursor > 0 ) {
            setCursor(cursor - 1)
          }
        } else if ( e.key === 'ArrowDown' ) {
          if ( showList && cursor < props.options.length ) {
            setCursor(cursor + 1);
          } 
        } else if ( e.key === 'Enter' ) {
          if ( showList ) {
            var newValue = props.options[cursor];
            props.onChange(newValue.value);
            setShowList(false);
          }
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      window.addEventListener('keydown', onKeyDown);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        window.removeEventListener('keydown', onKeyDown);
      };
    }, [ref, showList, cursor, props]);
  }
  useOutside(wrapperRef);

  const handleChange = async e => {
    props.onChange(e)
    setShowList(false);
  }

  const handleToggle = () => {
    let pos = wrapperRef.current 
      ? wrapperRef.current.getBoundingClientRect().top 
      : 0;
    let newIsUp = pos > window.innerHeight / 2;
    setIsUp(newIsUp);
    setShowList(!showList);
    console.log('is up: ', isUp);
  };

  const items = [];
  statuses.map((item, i) => {
    items.push(
      <button 
        index={i}
        key={`select-s-item-${i}`}
        className={item.value === props.value 
          ? 'select-s-item active' 
          : ( i === cursor 
            ? 'select-s-item select-s-item-hover' 
            : 'select-s-item' )
        }
        name={props.name}
        value={item.value}
        onClick={handleChange}
        onMouseEnter={e => setCursor(
          parseInt(e.target.getAttribute('index')) 
        )}
        onMouseLeave={e => setCursor(null)}
      >
        {item.label}
      </button>

    )
  })

  const label = () => {
    var l = statuses
      .filter(item => item.value === props.value)

    if ( l.length > 0 ) {
      if (mobil) {
        return l[0].label.slice(0,1)
      }
      return l[0].label
    } else {
      return ''
    }
  }

  return <div ref={wrapperRef} className='select-s'>  
    
    <button 
      id={`selected-s-${props.value}`}
      className='button secondary selected-s'
      name='value'
      value={ props.value }
      onClick={handleToggle}
    >
      { label() }
    </button>

    <AnimatePresence>
      { showList && <motion.div 
        className='bg select-s-items'
        initial={{ height: 0 }}
        animate={{ height: 'auto' }}
        exit={{ height: 0 }}
        style={{ 
          top: isUp ? '' : '44px', 
          bottom: isUp ? '44px' : ''
        }}
      >
        { items }
       </motion.div> }
    </AnimatePresence>
  </div>
}

export default SelectStatus;
