import React from 'react';

import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

import SelectUnitAdd from 'components/SelectUnitAdd';
import SelectProductAdd from 'components/SelectProductAdd';
import SelectVendorAdd from 'components/SelectVendorAdd';

import './AddItem.css';

const unitOptions = [
  { value: 'pz', label: 'Pz' },
  { value: 'kg', label: 'Kg' },
  { value: 'gr', label: 'gr' },
  { value: 'lt', label: 'lt' },
  { value: 'paq', label: 'Paquete' },
  { value: 'charola', label: 'Charola' },
  { value: 'mjo', label: 'Manojo' },
  { value: 'tapa', label: 'Tapa' },
  { value: 'caja', label: 'Caja' },
  { value: 'bolsa', label: 'Bolsas' },
  { value: 'bulto', label: 'Bulto' }
]

const vendorOptions = [
  { value: 'laviasana', label: 'La Vía Sana' },
  { value: 'costco', label: 'Costco' },
  { value: 'heb', label: 'HEB' },
  { value: 'ramos', label: 'Ramos' },
  { value: 'sams', label: 'SAMS' },
  { value: 'bodega', label: 'En Bodega' },
  { value: 'san_juan', label: 'San Juan' },
  { value: 'otros', label: 'Otros' },
  { value: 'notas', label: 'Notas' },
];

export default function AddItem(props) {
  
	const { open, list, productOptions } = props;

  const [values, setValues] = React.useState({
    qty: 1, 
    unit: unitOptions[0].value, 
    product: '', 
    vendor: vendorOptions[0].value
  });

  const handleSubmit = e => {
    props.handleAddProduct(values);
  };

  return <AnimatePresence>
    { open && <Container 
      className='flex'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { delay: 0.3 } }}
    >
      <motion.div 
        className='bg add-item'
        initial={{ y: window.innerHeight, opacity: 0 }}
        animate={{ y: 0, opacity: 1, transition: { delay: 0.3 } }}
        exit={{ y: window.innerHeight, opacity: 0 }}
      >
        <h2>Nuevo producto</h2>

        <div className='add-item-form'>
          <div className='add-item-input'>
            <h5>Vendor</h5>
            <SelectVendorAdd
              value={values.vendor} 
              name='vendor'
              options={ list.filter(item => item.vendor === 'notas').length > 0 ? vendorOptions.filter(item => item.value !== 'notas') : vendorOptions} 
              onChange={e => setValues({ ...values, vendor: e.target.value })}
            />
          </div>

          <div className='add-item-input'>
            <h5>Producto</h5>
            <SelectProductAdd
              value={values.product} 
              name='value'
              vendor={values.vendor}
              options={productOptions} 
              onChange={e => setValues({ ...values, product: e })}
            />
          </div>

          { values.vendor !== 'notas' && <div className='add-item-input'>
            <h5>Cantidad</h5>
            <input 
              className='input'
              type='number' 
              name='qty' 
              value={values.qty}
              onChange={e => setValues({ ...values, qty: e.target.value })}
            />
          </div> }

          { values.vendor !== 'notas' && <div className='add-item-input'>
            <h5>Unidad</h5>
            <SelectUnitAdd 
              value={values.unit} 
              name='unit'
              options={unitOptions} 
              onChange={e => setValues({ ...values, unit: e.target.value })}
            />
          </div> }
        </div>
        
        <Submit className='flex'>
          <button 
            className='button primary'
            name='confirm'
            onClick={handleSubmit}
            disabled={Object.values(values).includes('')}
          >
            Agregar
          </button>
        </Submit>

        <Close onClick={props.handleClose}>
          <i className='fas fa-times' />
        </Close>
      </motion.div>
    </Container> }
  </AnimatePresence>
};

const Container = styled(motion.div)`
  position: fixed;
  z-index: 888;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  background: rgba(0,0,0,0.5);
  backdrop-filter: blur(8px);
`;
const Submit = styled.div`
  justify-content: flex-end;

  & button {
    height: 40px;
    padding: 0px 16px;
  }
`;
const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 16px;

  outline: none;
  border: none;
  background: transparent;

  font-size: 1rem;
  color: #222;
  
  &:active {
    color: #C767E5;
  }
`;