import React, { useState, useEffect, useRef } from 'react';

import SelectUnit from 'components/SelectUnit'
import SelectProduct from 'components/SelectProduct'
import SelectVendor from 'components/SelectVendor'

import { AnimatePresence, motion } from "framer-motion";
import { API } from "aws-amplify";
import * as mutations from 'graphql/_mutations';
// import * as queries from '../graphql/_queries';

import './Item.css';

const unitOptions = [
  { value: 'pz', label: 'Pz' },
  { value: 'kg', label: 'Kg' },
  { value: 'gr', label: 'gr' },
  { value: 'lt', label: 'lt' },
  { value: 'paq', label: 'Paq' },
  { value: 'mjo', label: 'Man' },
  { value: 'charola', label: 'Char' },
  { value: 'tapa', label: 'Tapa' },
  { value: 'caja', label: 'Caja' },
  { value: 'bolsa', label: 'Bol' },
  { value: 'bulto', label: 'Bul' }
]

const vendorOptions = [
  { value: 'notas', label: 'Notas' },
  { value: 'laviasana', label: 'La Vía Sana' },
  { value: 'costco', label: 'Costco' },
  { value: 'heb', label: 'HEB' },
  { value: 'ramos', label: 'Ramos' },
  { value: 'sams', label: 'SAMS' },
  { value: 'bodega', label: 'En Bodega' },
  { value: 'san_juan', label: 'San Juan' },
  { value: 'otros', label: 'Otros' },
];

function cleanString(str) {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/ +(?= )/g,'')
    .replace(/[,.]/g,'')
    .toLowerCase()
    .trim()
}

export default function Item(props) {

  const { 
    mobil, index, filters, length, productOptions,
    values
  } = props;

  // const [values, setValues] = useState(props.values);
  const [active, setActive] = React.useState(true);

  // const [isUp, setIsUp] = useState(true);
  const [isChange, setIsChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const wrapperRef = React.useRef(null);
  const isUp = 0;

  const timeframe = 1 / length;

  // React.useEffect(() => {
  //   setValues(props.values);
  // }, [props.values]);

  const handleSearch = (value, search) => {
    value = value.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase();
    search = search.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase();
    return value.includes(search);
  };

  const handleChange = async e => {
    setIsChange(true);
    // setValues({ ...values, [e.target.name]: e.target.value});
  };

  const handleProduct = async e => {
    setIsChange(true);
      
    var prod = props.catalog
      .filter(item => cleanString(item.product) === cleanString(e));
    
    var sub_category = '-';
    var category = '-';

    if ( prod.length > 0 ) {
      sub_category = prod[0]['sub_category'];
      category = prod[0]['category'];
    } 
  
    // setValues({ ...values, 
    //   product: e,
    //   sub_category: sub_category,
    //   category: category
    // });
  };

  const handleEdit = e => {
    props.handleEdit(values)
  };

  const handleCancel = async e => {
    // setValues(JSON.parse(JSON.stringify(props.values)));
    setIsChange(false);
  };

  const handleSave = async e => {
      
    try {
      setIsLoading(true);
      
      console.log('save req: ', values);
      delete values.createdAt;
      delete values.updatedAt;
      let res = await API.graphql({ 
        query: mutations.updateProduct, 
        variables: { input: values }
      })
      console.log('save res: ', res);
      res = res.data.updateLaViaSana
      console.log('save data: ', res);
      setIsChange(false);
      setIsLoading(false);
      props.handleUpdateOrder(values);

    } catch(err) {
      setIsLoading(false);
      alert("Error updating item, please contact support.")
      console.log('Error updating item', err)
    }
  };

  return <AnimatePresence>
    <motion.div 
      ref={wrapperRef}
      key={values.id}
      className='item'
      style={{
        backgroundColor: 'sub_category' in values 
          && values.vendor === 'laviasana'
          && values.sub_category === '-' 
            ? 'rgb(250,0,0,0.2)' 
          : (index+1) % 2 === 0 ? '#C767E522' : 'transparent'
      }}
      initial={{ 
        opacity: 0, 
        // transition: { delay: index * timeframe } 
      }}
      animate={{ 
        opacity: 1, 
        transition: { delay: index * timeframe } 
      }}
      exit={{ 
        x: window.innerWidth / 2, 
        opacity: 0,
        transition: { delay: index * timeframe } 
      }}
    >
      <div 
        className={ values.vendor !== 'notas' ? 'item-data' : 'item-data active' }
        // className='item-data'
        style={{ pointerEvents: mobil ? true : false }}
      >
        <span className='item-index'>
          {index+1}
        </span>

        { values.vendor !== 'notas' && <input 
            className='input'
            type="numeric" 
            id={values.id} 
            value={values.qty || ''} 
            name='qty' 
            onChange={handleChange}
          />
        }
        
        { values.vendor !== 'notas' && <SelectUnit 
            isUp={isUp} 
            id={values.id} 
            value={values.unit || ''} 
            name='unit'
            options={unitOptions} 
            onChange={handleChange}
          />
        }

        <SelectProduct
          isUp={isUp} 
          id={values.id} 
          vendor={values.vendor} 
          value={values.product || ''} 
          name='product'
          options={productOptions} 
          onChange={handleProduct}
        />
        
        { values.vendor !== 'notas' && <span className='item-subdata'>
            { 'sub_category' in values ? values.sub_category : '-' }{', '}
            { 'category' in values ? values.category : '-' }
          </span>
        }

        <SelectVendor
          id={values.id} 
          isUp={wrapperRef.current && wrapperRef.current.getBoundingClientRect().top || 0} 
          value={values.vendor || ''} 
          name='vendor'
          options={vendorOptions} 
          onChange={handleChange}
        />

        {/*ACTION*/}
        <button className='item-action' onClick={handleEdit} />
      </div>

      { isChange ? <div className='item-changes'>
        <div>
          <button
            name='cancel'
            value={values.id}
            onClick={handleCancel}
          >
            <span><i className='fas fa-times' /></span>
          </button>
        </div>
          
        <div>
          <button
            id='item-save-changes'
            name='save'
            value={values.id}
            onClick={handleSave}
          >
            <span><i className='fas fa-check' /></span>
          </button>
        </div>
      </div> : <div className='item-delete'>
        <button
          name='deleteProduct'
          value={values.id}
          onClick={props.handleDelete}
        >
          <span><i className='fas fa-times' /></span>
        </button>
      </div> }
    </motion.div>
  </AnimatePresence>

};
