import React from 'react';

import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import SelectUnitAdd from 'components/SelectUnitAdd';
import SelectProductAdd from 'components/SelectProductAdd';
import SelectVendorAdd from 'components/SelectVendorAdd';

const unitOptions = [
  { value: 'pz', label: 'Pz' },
  { value: 'kg', label: 'Kg' },
  { value: 'gr', label: 'gr' },
  { value: 'lt', label: 'lt' },
  { value: 'paq', label: 'Paquete' },
  { value: 'charola', label: 'Charola' },
  { value: 'mjo', label: 'Manojo' },
  { value: 'tapa', label: 'Tapa' },
  { value: 'caja', label: 'Caja' },
  { value: 'bolsa', label: 'Bolsas' },
  { value: 'bulto', label: 'Bulto' }
]

const vendorOptions = [
  { value: 'notas', label: 'Notas' },
  { value: 'laviasana', label: 'La Vía Sana' },
  { value: 'costco', label: 'Costco' },
  { value: 'heb', label: 'HEB' },
  { value: 'ramos', label: 'Ramos' },
  { value: 'sams', label: 'SAMS' },
  { value: 'bodega', label: 'En Bodega' },
  { value: 'san_juan', label: 'San Juan' },
  { value: 'otros', label: 'Otros' },
];

export default function DeleteItem(props) {
  
	const { list, productOptions, deleteProduct } = props;

  const [values, setValues] = React.useState({
    qty: 1, 
    unit: unitOptions[0].value, 
    product: '', 
    vendor: vendorOptions[0].value
  })

  var data = list.filter(o => o.id === deleteProduct.id);

  if ( !data.length ) return ''
  
  return <Container className='flex'>
    <Content>
      <h3>Eliminar producto</h3>

      <p>
        ¿Estas seguro de eliminar el &nbsp;
        <span>{data[0].product}</span>
        ?
      </p>
      
      <Actions>
        <button 
          className='button secondary'
          name='cancel'
          onClick={props.handleDeleteProduct}
        >
          CANCELAR
        </button>

        <button 
          className='button primary'
          name='confirm'
          value={deleteProduct.id}
          onClick={props.handleDeleteProduct}
        >
          ELIMINAR
        </button>
      </Actions>
    </Content>
  </Container>
};

const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 777;
  height: 100%;
  width: 100%;

  background-color: rgba(0,0,0,0.5);
  backdrop-filter: blur(8px);

  @media (min-width: 666px)  {
  }
`;
const Content = styled(motion.div)`
  position: relative;
  width: calc(100% - 32px);
  padding: 16px;

  border: 2px solid #D0BDE2;
  border-radius: 16px;

  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2); 
  background-color: #F0F0F0;
  
  & p {
    font-size: 1rem;

    & span {
      font-weight: bold;   
    }
  }

  @media (min-width: 666px)  {
    width: auto;
  }
`;

const Actions = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  grid-gap: 8px;

  & button {
    height: 40px;
    padding: 0px 16px;
  }
`;