/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getLaViaSana = /* GraphQL */ `
  query GetLaViaSana($id: ID!, $sk: String!) {
    getLaViaSana(id: $id, sk: $sk) {
      id
      sk
      dk
      status
      date
      customer
      price_level
      product
      category
      sub_category
      unit
      vendor
      qty
      message
      context
      pz
      kg
      gr
      lt
      paq
      charola
      mjo
      tapa
      caja
      bulto
      bolsa
      iva
      costo
      margen_a
      precio_a
      margen_b
      precio_b
      margen_c
      precio_c
      address
      latitud
      longitud
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listLaViaSanas = /* GraphQL */ `
  query ListLaViaSanas(
    $id: ID
    $sk: ModelStringKeyConditionInput
    $filter: ModellaViaSanaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLaViaSanas(
      id: $id
      sk: $sk
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sk
        dk
        status
        date
        customer
        price_level
        product
        category
        sub_category
        unit
        vendor
        qty
        message
        context
        pz
        kg
        gr
        lt
        paq
        charola
        mjo
        tapa
        caja
        bulto
        bolsa
        iva
        costo
        margen_a
        precio_a
        margen_b
        precio_b
        margen_c
        precio_c
        address
        latitud
        longitud
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const gS1 = /* GraphQL */ `
  query GS1(
    $sk: String
    $dk: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModellaViaSanaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    GS1(
      sk: $sk
      dk: $dk
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sk
        dk
        status
        date
        customer
        price_level
        product
        category
        sub_category
        unit
        vendor
        qty
        message
        context
        pz
        kg
        gr
        lt
        paq
        charola
        mjo
        tapa
        caja
        bulto
        bolsa
        iva
        costo
        margen_a
        precio_a
        margen_b
        precio_b
        margen_c
        precio_c
        address
        latitud
        longitud
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const ordersByDate = /* GraphQL */ `
  query OrdersByDate(
    $sk: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModellaViaSanaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByDate(
      sk: $sk
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sk
        dk
        status
        date
        customer
        price_level
        product
        category
        sub_category
        unit
        vendor
        qty
        message
        context
        pz
        kg
        gr
        lt
        paq
        charola
        mjo
        tapa
        caja
        bulto
        bolsa
        iva
        costo
        margen_a
        precio_a
        margen_b
        precio_b
        margen_c
        precio_c
        address
        latitud
        longitud
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;


export const getOrder = /* GraphQL */ `
  query GetLaViaSana($id: ID!, $sk: String!) {
    getLaViaSana(id: $id, sk: $sk) {
      id
      sk
      dk
      date
      customer
      message
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query OrdersByDate(
    $sk: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModellaViaSanaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByDate(
      sk: $sk
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sk
        dk
        date
        customer
        message
      }
      nextToken
    }
  }
`;

export const listItems = /* GraphQL */ `
  query ListLaViaSanas(
    $id: ID
    $sk: ModelStringKeyConditionInput
    $filter: ModellaViaSanaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLaViaSanas(
      id: $id
      sk: $sk
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sk
        customer
        dk
        date
        sub_category
        category
        unit
        vendor
        qty
        message
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listOrder = /* GraphQL */ `
  query ListLaViaSanas(
    $id: ID
    $sk: ModelStringKeyConditionInput
    $filter: ModellaViaSanaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLaViaSanas(
      id: $id
      sk: $sk
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sk
        dk
        sub_category
        category
        unit
        vendor
        qty
      }
      nextToken
    }
  }
`;
export const listCatalog = /* GraphQL */ `
  query Gs1(
    $sk: String
    $dk: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModellaViaSanaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    GS1(
      sk: $sk
      dk: $dk
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sk
        dk
        category
        sub_category
        product
        unit
        pz
        charola
        bulto
        lt
        mjo
        tapa
        kg
        paq
        bolsa
        caja
        gr
        iva
        costo
        precio_a
        precio_b
        precio_c
        margen_a
        margen_b
        margen_c
      }
      nextToken
    }
  }
`;
export const listCRM = /* GraphQL */ `
  query Gs1(
    $sk: String
    $dk: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModellaViaSanaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    GS1(
      sk: $sk
      dk: $dk
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sk
        dk
        date
        address
        latitud
        longitud
        precio_a
      }
      nextToken
    }
  }
`;
export const gs1LIST = /* GraphQL */ `
  query Gs1(
    $sk: String
    $dk: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModellaViaSanaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    GS1(
      sk: $sk
      dk: $dk
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sk
        dk
        product
        sub_category
        category
        unit
        vendor
        qty
        message
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const gs1Cache = /* GraphQL */ `
  query Gs1(
    $sk: String
    $dk: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModellaViaSanaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    GS1(
      sk: $sk
      dk: $dk
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sk
        dk
        date
        message
      }
      nextToken
    }
  }
`;
export const getCache = /* GraphQL */ `
  query GetLaViaSana($id: ID!, $sk: String!) {
    getLaViaSana(id: $id, sk: $sk) {
      id
      sk
      dk
      date
      message      
    }
  }
`;
