import React from 'react';

import './Footer.css';

function Footer(props) {
  return <div className='footer'>
    <div className='footer-messages'>
      <p>
        <a>Política de privacidad</a>
      </p>
      <p>
        <a>Términos y condiciones</a>
      </p>
    </div>
    <div className='footer-logo'>
      ©&nbsp;
      <h2>
        <a href="https://sandpalace.io" target='_blank'>
          <span>Sand</span>Palace
        </a>
      </h2>
    </div>
  </div>
}

export default Footer;