import React, { useState, useEffect, useRef } from 'react';

import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { API } from "aws-amplify";
import * as mutations from 'graphql/_mutations';

import SelectStatus from 'components/SelectStatus';

import './Details.css';

export default function Details(props) {

  const { mobil, index, length, values, filters } = props;

  const timeframe = 1 / length;
  
  const [active, setActive] = useState(true);
  const [isChange, setIsChange] = useState(false);
  const [isCursor, setIsCursor] = useState(false);
  const [isUp, setIsUp] = useState(true);

  const wrapperRef = useRef(null);

  function useOutside(ref) {
    useEffect(() => {
      function handlePosition(e) {
        if ( ref.current ) {
          var el = document
            .getElementsByName('details-'+index)[0]
            .getBoundingClientRect();

          var pos = el.bottom / 2
          if ( pos + 70 > window.innerHeight / 2 ) {
            setIsUp(true)
          } else {
            setIsUp(false)
          }
        }
      }

      window.addEventListener('click', handlePosition);
      return () => {
        window.removeEventListener('click', handlePosition);
      };
    }, [ref]);
  }
  useOutside(wrapperRef);

  return <AnimatePresence>
    <motion.div 
      ref={wrapperRef}
      key={`details-${index}`} 
      name={`details-${index}`} 
      className='details'
      id={`details-${values.dk}`}

      initial={{ 
        x: -window.innerWidth / 2, 
        opacity: 0, 
        // transition: { delay: index * timeframe } 
      }}
      animate={{ 
        x: 0, 
        opacity: 1, 
        transition: { delay: index * timeframe } 
      }}
      exit={{ 
        x: window.innerWidth / 2, 
        opacity: 0,
        transition: { delay: index * timeframe } 
      }}

      onMouseEnter={() => setIsCursor(true)}
      onMouseLeave={() => setIsCursor(false)}
    >
      <Link 
        className='details-action'
        to={values.id}
      />

      {/*<span>
        {props.index+1}
      </span>*/}
      <div className='flex' style={{ justifyContent: 'flex-start' }}>
        <span>
          {props.formatDate(values.date)}
        </span>
      </div>
      
      <div 
        className='flex' 
        style={{ 
          overflow: 'hidden',
          justifyContent: 'flex-start',
          textOverflow: 'ellipsis',
          borderLeft: '1px solid #ddd',
          borderRight: '1px solid #ddd',
        }}
      >
        <span>{values.customer}</span>
      </div>
      
      <div className='flex'>
        <SelectStatus
          mobil={mobil} 
          isUp={isUp}
          name={values.id} 
          value={values.dk}
          onChange={props.handleStatus}
        />
      </div>
      
      <div className='details-delete'>
        <button
          name='delete'
          value={props.values.id}
          onClick={props.deleteOrder}
        >
          <i className='fas fa-times' />
        </button>
      </div>

    </motion.div>
  </AnimatePresence>
};
