import React from 'react';

import styled from 'styled-components';
import { Auth, API, Hub, Storage } from 'aws-amplify';
import * as queries from 'graphql/_queries';
import * as mutations from 'graphql/_mutations';
import * as subscriptions from 'graphql/subscriptions';

import { AnimatePresence, motion } from 'framer-motion';
import { 
  Outlet, useOutletContext, 
  useLocation, useNavigate, Link,
  useSearchParams, createSearchParams 
} from 'react-router-dom';

import './PageLayout.css';

export default function PageLayout(props) {
  
  const { mobil, isLoggedIn } = useOutletContext();

  const [subscription, setSubscription] = React.useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const docRef = React.useRef(null);
  const authRef = React.useRef(null);
  
  let location = useLocation();
  let navigate = useNavigate();
  const { state } = location;

  // ESCAPE AUTH WITH ESCAPE
  function useEscape(ref) {
    React.useEffect(() => {
      function onKeyDown(e) {
        if ( e.key === 'Escape' ) {
          if ( searchParams.get('auth') ) {
            // handleCloseAuth();
          }
          if ( searchParams.get('precios') ) {
            // handleClosePrices();
          }
        } 
      }

      window.addEventListener('keydown', onKeyDown);
      return () => {
        window.removeEventListener('keydown', onKeyDown);
      };
    }, [ref, searchParams]);
  };
  useEscape(authRef);

  // PROCESS SUBSCRIPTIONS
  React.useEffect(() => {
    
    let componentMounted = true;
    // if ( !user || user.pk === 'guest|' ) return
    
    let subscribe; let subscribeGSI1; let subscribeGSI2;
    // subscribe = API.graphql({
    //   query: subscriptions.onPrimary,
    //   variables: { pk: user.sk }
    // }).subscribe({
    //   next: ({ provider, value }) => {
    //     if ( value.errors ) {
    //       console.warn('Error in subscription primary: ', value.errors)
    //     }
    //     var data = value.data.onPrimary;
    //     if ( data ) {
    //       Object.keys(data).forEach(k => {
    //         if ( [null, undefined, ""].includes(data[k]) ) delete data[k];
    //       });
    //       setSubscription(data);
    //     }
    //   },
    //   error: error => console.warn(error)
    // });

    // subscribeGSI1 = API.graphql({
    //   query: subscriptions.onGSI1,
    //   variables: { gsi1pk: user.sk }
    // }).subscribe({
    //   next: ({ provider, value }) => {
    //     if ( value.errors ) {
    //       console.warn('Error in subscription gsi1: ', value.errors)
    //     }
    //     var data = value.data.onGSI1;
    //     if ( data ) {
    //       Object.keys(data).forEach(k => {
    //         if ( [null, undefined, ""].includes(data[k]) ) delete data[k];
    //       });
    //       setSubscription(data);
    //     }
    //   },
    //   error: error => console.warn(error)
    // });

    // subscribeGSI2 = API.graphql({
    //   query: subscriptions.onGSI2,
    //   variables: { gsi2pk: user.sk }
    // }).subscribe({
    //   next: ({ provider, value }) => {
    //     if ( value.errors ) {
    //       console.warn('Error in subscription gsi2: ', value.errors)
    //     }
    //     var data = value.data.onGSI2;
    //     if ( data ) {
    //       Object.keys(data).forEach(k => {
    //         if ( [null, undefined, ""].includes(data[k]) ) delete data[k];
    //       });
    //       setSubscription(data);
    //     }
    //   },
    //   error: error => console.warn(error)
    // });

    return () => {
     componentMounted = false;
     // if ( subscribe ) subscribe.unsubscribe();
     // if ( subscribeGSI1 ) subscribeGSI1.unsubscribe();
     // if ( subscribeGSI2 ) subscribeGSI2.unsubscribe();
    }
  }, []);

  React.useEffect(() => {
    if ( subscription ) {
      processSubscription(subscription);
      setSubscription(null);
    }
  }, [subscription]);

  const processSubscription = async subscription => {
    if ( subscription.pk === 'user|' ) {
      console.log('new user: ', subscription);
      // updateUser();
    } else if ( subscription.pk === 'guest|' ) {
      console.log('new guest: ', subscription);
      // updateUser(subscription);
    } 
  };

  return <div 
    ref={docRef} 
    className='layout-container'
    // style={{ height: mobil ? `${size[1]}px` : '' }} 
  > 
    <div className='layout bg'>
      <Outlet context={{ 
        isLoggedIn,
        mobil
      }} />
    </div>
  </div>
};
