import React, { useState, forwardRef } from 'react';

import { API } from "aws-amplify";
import * as mutations from 'graphql/_mutations';
import * as queries from 'graphql/_queries';

import styled from 'styled-components';
import { motion } from "framer-motion";

import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es"; // the locale you want
import "react-datepicker/dist/react-datepicker.css";

import Actions from '../Actions';

import './index.css';

registerLocale("es", es); // register it with the name you want

var statuses = [
  {value: 'open', label: 'Abierta'},
  {value: 'converted', label: 'Convertida'},
  {value: 'validated', label: 'Validada'},
  {value: 'consolidated', label: 'Consolidada'}
];

export default function FiltersOrders(props) {

  const { mobil, filters, status } = props;

  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [isChange, setIsChange] = React.useState(false);

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  };

  const handleSearch = e => {
    setSearch(e.target.value);
    props.handleFilters(e)
  };

  const handleStatus = e => {  
    props.handleFilters(e)
  };

  const handleStartDate = date => {
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    console.log('start date: ', date.toISOString());

    let e = { target: { name: 'startDate', value: date.toISOString() }};
    props.handleFilters(e);
  };

  const handleEndDate = date => {
    date.setHours(23)
    date.setMinutes(59)
    date.setSeconds(59)
    date.setMilliseconds(999)
    // const utcTimestamp = date.getTime();
    // const localTimezoneOffset = new Date().getTimezoneOffset();
    // const localTimestamp = utcTimestamp - localTimezoneOffset * 60000;
    // const localDate = new Date(localTimestamp);
    console.log('end date: ', date.toISOString());

    let e = { target: { name: 'endDate', value: date.toISOString() }};
    props.handleFilters(e);
  };

  // COMPONENTS
  const DateFilter = (props) => {

    const { startDate, endDate } = props.dates;

    if ( startDate ) {
      const ref = React.createRef();
      const DatePickerTagStart = forwardRef(({ onClick, value }, ref) => {
        var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
        var months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
        var days_es = ['Domingo','Lunes','Martes','Miércoles','Jueves','Viernes','Sábado'];
        var months_es = ['enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre'];

        var day0 = new Date(startDate);
        var month0 = months_es[day0.getMonth()];
        month0 = month0.charAt(0).toUpperCase() + month0.slice(1,3)

        return <button 
          className='filters-o-date-toggle' 
          onClick={onClick} 
          ref={ref}
        >
          <i className='fas fa-calendar-alt' />
          {`${day0.getDate()} ${month0}`}
          <i className='fas fa-chevron-down' />
        </button>
      });

      const DatePickerTagEnd = forwardRef(({ onClick, value }, ref) => {
        var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
        var months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
        var days_es = ['Domingo','Lunes','Martes','Miércoles','Jueves','Viernes','Sábado'];
        var months_es = ['enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre'];

        var day0 = new Date(endDate);
        var month0 = months_es[day0.getMonth()];
        month0 = month0.charAt(0).toUpperCase() + month0.slice(1,3)

        return <button 
          className='filters-o-date-toggle' 
          onClick={onClick} 
          ref={ref}
        >
          <i className='fas fa-calendar-alt' />
          {`${day0.getDate()} ${month0}`}
          <i className='fas fa-chevron-down' />
        </button>
      });

      return <section>
        <h4>Fechas</h4>
        <div className='filters-o-date-inputs'>
          <div className='filters-o-date-input'>
            <DatePicker
              locale="es"
              customInput={<DatePickerTagStart />}
              className="filters-o-date"
              onChange={handleStartDate}
              selected={startDate}
              maxDate={endDate}
              popperPlacement="bottom-start"
            />
          </div>
          <div className='filters-o-date-input'>
            <DatePicker
              locale="es"
              customInput={<DatePickerTagEnd />}
              className="filters-o-date"
              onChange={handleEndDate}
              selected={endDate}
              minDate={startDate}
              maxDate={new Date()}
              popperPlacement="bottom-end"
            />
          </div>
        </div>
      </section>
    }
  };

  const variants = {
    visible: { height: '100%' },
    hidden: { height: '80px' }
  };

  return <Container 
    className='bg' 
    variants={variants}
    initial={mobil ? 'hidden' : 'visible'}
    animate={mobil 
      ? ( open ? 'visible' : 'hidden' )
      : 'visible'
    }
    transition={{ ease: 'easeIn' }}
  >

    {/*Customer search*/}
    <section>
      <Header>
        <Search>
          <h4>Buscar cliente</h4>
          <div>
            <input 
              className='input'
              type='text'
              name='customer'
              value={search}
              onChange={handleSearch}
            />
            <SearchClose 
              name='customer'
              value=''
              disabled={!search}
              onClick={handleSearch}
            >
              <i className='fas fa-times' />
            </SearchClose>
          </div>
        </Search>
        { mobil && <More
          className='button secondary'
          onClick={() => setOpen(!open)}
        >
          { open ? 
              <i className='fas fa-times' /> : 
              <i className='fas fa-filter' />
          }
        </More> }
      </Header>
    </section>

    {/*Status*/}
    <section>
      <h4>Estatus</h4>
      <div className='filters-o-statuses'>
        { statuses.map((s, i) => <button 
          key={i}
          className={ filters.status[s.value] 
            ? 'filters-o-status active' 
            : 'filters-o-status' }
          name='status'
          value={s.value}
          onClick={handleStatus}
        >
          { filters.status[s.value]
            ? <i className='fas fa-check-square' />
            : <i className='fas fa-square' />
          } {s.label}
        </button> )}
      </div>
    </section>

    <DateFilter 
      dates={{ 
        startDate: new Date(filters.startDate), 
        endDate: new Date(filters.endDate) 
      }}
      handleStartDate={handleStartDate}
      handleEndDate={handleEndDate}
    />

    { !mobil && <Actions 
      mobil={mobil} 
      status={status} 
      handleReset={props.handleReset}
      handleValidation={props.handleValidation}
      handleConsolidation={props.handleConsolidation}
    /> }
  </Container>
};

const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0px 16px;
  z-index: 10;
  overflow: hidden;
  
  border-radius: inherit;

  & section {
    margin-bottom: 10px;
    padding-bottom: 20px;
    
    border-bottom: 1px solid #ccc;
    
    & h4 {
      margin-bottom: 6px;
      color: #46005D;
    }
  }

  @media (min-width: 666px) {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 20px;
    overflow: hidden;
    
    border: 2px solid #D0BDE2;
    border-radius: 20px;
  }
`;
const Header = styled.div`
  height: 80px;
  padding-bottom: 6px;
  display: grid;
  grid-template-columns: auto 40px;
  align-items: flex-end;
  grid-gap: 4px;

  @media (min-width: 666px)  {
    display: flex;
    width: 100%;
  }
`;
const Search = styled.div`
  position: relative;

  & div {
    position: relative;

    & input {
      height: 40px;
      width: 100%;
    }
  }

  @media (min-width: 666px)  {
    width: 100%;
  }
`;
const SearchClose = styled.button`
  transition-duration: 0.3s;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  outline: none;
  border: none;
  border-radius: 50px;
  background-color: #222222;

  &:hover {
    background-color: #222222aa;
  }
  &:disabled {
    pointer-events: none;
    background-color: #cccccc;
  }
  & i {
    pointer-events: none;
    color: #fff;
    font-size: 11px;
  }
`;

const More = styled.button`
  transition-duration: 0.3s;
  height: 40px;
  width: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  
  outline: none;
  border: none;
  background-color: transparent;

  &:hover {
    color: #C767E5;
  }
`;