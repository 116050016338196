import React, { useState, useEffect, useRef } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import './SelectUnit.css';

function SelectUnit(props) {
  
  const [cursor, setCursor] = useState(0);
  const [showList, setShowList] = useState(false);
  const [isUp, setIsUp] = React.useState(true);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if ( 'options' in props ) {
      props.options.forEach((item, i) => {
        if ( item.value === props.value ) {
          setCursor(i);
          return true
        }
      })
    }
  }, []);

  function useOutside(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // console.log(ref.current, event.target);
          setShowList(false);
        }
      }
      
      function onKeyDown(e) {
        if (e.key === 'Escape') {
          setShowList(false);
        } else if ( e.key === 'ArrowUp' ) {
          if ( showList && cursor > 0 ) {
            setCursor(cursor - 1)
          }
        } else if ( e.key === 'ArrowDown' ) {
          if ( showList && cursor < props.options.length ) {
            setCursor(cursor + 1);
          } 
        } else if ( e.key === 'Enter' ) {
          if ( showList ) {
            var newValue = props.options[cursor];
            props.onChange(newValue.value);
            setShowList(false);
          }
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      window.addEventListener('keydown', onKeyDown);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        window.removeEventListener('keydown', onKeyDown);
      };
    }, [ref, showList, cursor, props]);
  }
  useOutside(wrapperRef);

  const handleChange = async e => {
    props.onChange(e)
    setShowList(false);
  }

  const handleToggle = () => {
    let pos = wrapperRef.current 
      ? wrapperRef.current.getBoundingClientRect().top 
      : 0;
    let newIsUp = pos > window.innerHeight / 2;
    setIsUp(newIsUp);
    setShowList(!showList);
    console.log('is up: ', isUp);
  };

  const label = () => {
    var l = props.options
      .filter(item => item.value === props.value)

    if ( l.length > 0 ) {
      return l[0].label
    } else {
      return ''
    }
  }

  return <div ref={wrapperRef} className='select'>  
    
    <button 
      className='button secondary'
      name='value'
      value={ props.value }
      onClick={handleToggle}
    >
      { label() }
    </button>

    <AnimatePresence>
      { showList && <motion.div 
        className='bg select-items'
        initial={{ height: 0 }}
        animate={{ height: 'auto' }}
        exit={{ height: 0 }}
        style={{ 
          top: isUp ? '' : '44px', 
          bottom: isUp ? '44px' : ''
        }}
      >
        { props.options.map((item, i) => (
            <button 
              key={`select-item-${i}`}
              className={item.value === props.value 
                ? 'select-item active' 
                : ( i === cursor 
                  ? 'select-item select-item-hover' 
                  : 'select-item' )
              }
              id={props.id}
              name={props.name}
              value={item.value}
              label={item.label}
              onClick={handleChange}
              onMouseEnter={e => setCursor(
                parseInt(e.target.getAttribute('index')) 
              )}
              onMouseLeave={e => setCursor(null)}
            >
              {item.label}
            </button>
        ))}
      </motion.div> }
    </AnimatePresence>
  </div>
}

export default SelectUnit;
