import React from 'react';

import { Auth } from 'aws-amplify';
import { motion, AnimatePresence } from 'framer-motion';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';

import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import Orders from './Orders/index';

import './index.css';

export default function Home(props) {

  const { mobil } = props;

  const [menu, setMenu] = React.useState(false)
  
  const location = useLocation();
  const navigate = useNavigate();

  const tabs = [
    { label: 'Pedidos', value: '/pedidos'}, 
    { label: 'Reportes', value: '/reportes'}, 
    { label: 'Recordatorios', value: '/recordatorios'}, 
    { label: 'Soporte', value: '/soporte'}
  ];

  React.useEffect(() => {
    if ( location.pathname === '/' ) {
      navigate('/pedidos', { replace: true });
    }
  }, []);

  return <div className="home bg">    
    <Navbar 
      mobil={mobil} 
      menu={menu} 
      onMenu={() => setMenu(!menu)} 
    />
    
    <div className='home-content'>
      {/*SUBMENU*/}
      <AnimatePresence>
        { (!mobil || (mobil && menu)) && <motion.div 
          className='submenu'
          initial={{ x: mobil ? -window.innerWidth : 0 }}
          animate={{ x: 0 }}
          exit={{ x: mobil ? -window.innerWidth : 0 }}
          transition={{ ease: 'easeOut' }}
        >
          { tabs.map((t, i) => <Link 
              key={i}
              className={location.pathname.includes(t.value)
                ? 'submenu-item active' 
                : 'submenu-item'}
              to={t.value}
            >
              {t.label}
            </Link>
          )}

          { mobil && <>
            <button className='submenu-item'>
              <i className='fas fa-envelope' />
              &nbsp;
              Contactar soporte
            </button>
            <button 
              className='submenu-item'
              onClick={() => Auth.signOut()}
            >
              <i className='fas fa-sign-out-alt' />
              &nbsp;
              Cerrar sesión
            </button>
          </> }
        </motion.div> }
      </AnimatePresence>

      <Outlet context={{ mobil }} />
    </div>
    
    <Footer mobil={mobil} />
  </div>

};
