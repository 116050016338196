import React from 'react';

import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';

import './Results.css';

export default function Results(props) {
  
  const { open, url } = props;

  React.useEffect(() => {
    console.log('results: ', open, url);
  }, []);

  return <AnimatePresence>
    { open && <Container
      className='flex'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { delay: 0.3 }}}
    >
      <Content
        initial={{ y: -window.innerHeight }}
        animate={{ y: 0, transition: { delay: 0.3 } }}
        exit={{ y: -window.innerHeight }}
      >
        <BG />
        <Text className='flex'>
          <p>
            <h3>Consulta el consolidado en:</h3>
            <br/>
            <button onClick={props.handleResults}>
              {url}
            </button>
          </p>
        </Text>

        <Close 
          className='flex'
          onClick={props.handleClose}
        >
          <i className='fas fa-times' />
        </Close>
      </Content>
    </Container> }
  </AnimatePresence>
};

const Container = styled(motion.div)`
  cursor: default;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100%;
  width: 100%;

  background-color: rgba(0,0,0,0.5);
  backdrop-filter: blur(8px);
`;

const Content = styled(motion.div)`
  position: relative;
  width: calc(100% - 32px);
  height: 300px;
  overflow: hidden;

  border: 2px solid #D0BDE2;
  border-radius: 16px;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2); 

  @media (min-width: 666px) {
    height: 300px;
    width: 840px;
  }
`;

const BG = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;    
  transform: translate(-50%, -50%);
  height: 180%;
  width: 100%;

  background-image: url('gifs/fruit-dancing-gif.gif');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
const Text = styled.div`
  position: absolute;
  top: 0;
  left: 0;    
  height: 100%;
  width: 100%;

  background-color: rgba(255,255,255,0.7);

  & p {

    
    & button {
      transition-duration: 0.3s;
      cursor: pointer;
      width: 100%;
      padding: 8px;
      
      outline: none;
      border: none;
      background-color: transparent;

      color: #222;
      text-align: left;
      font-size: 0.875rem;
      font-weight: bold;
      font-family: 'Nunito', sans-serif;
      border: 1px solid transparent;
      
      &:hover {
        color: #C767E5;
        border: 1px solid #C767E5;
      }
      &:active {
        color: #adbf5a;
        border-bottom: 1px solid #adbf5a;
      }
    }
  }
`;
const Close = styled.button`
  transition-duration: 0.3s;
  cursor: default;
  position: absolute;
  right: 0px;
  top: 0px;
  margin: 12px;

  border: none;
  border-radius: 30px;
  outline: none;
  background-color: transparent;

  height: 18px;
  width: 18px;

  color: #aaa;
  font-size: 1rem;

  &:hover {
    color: red;
  }
`;
