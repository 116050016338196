import React from "react";

import { Auth } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';

import './index.css'

export default function SignIn(props) {

  const [isLoading, setIsLoading] = React.useState(false);
  const [values, setValues] = React.useState({
    username: '', password: ''
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleSignIn = async () => {
    try {
      setIsLoading(true);
      let user = await Auth.signIn(values.username, values.password);
      console.log('user signed in: ', user)
      setIsLoading(false);
      let newLoc = state?.path || '/';
      navigate(newLoc, { replace: true });
    } catch(err) {
      console.log('Error signing in: ', err);
    };
  }

  return <div className='auth-form-container'>
    <h1>¡Bienvenido!</h1>
    
    <div className="auth-form">

      <div className="auth-form-input-container">
        <label 
          htmlFor="username"
          className="auth-form-label"
        >Usuario</label>
        <br/>
        <input
          className="input"
          key="username"
          name="username"
          value={values.username}
          onChange={handleChange}
          type="text"
          placeholder="Escribe el nombre de tu usuario"
        />
      </div>

      <div className="auth-form-input-container">
        <label 
          htmlFor="password"
          className="auth-form-label"
        >Password</label>
        <br/>
        <input
          className="input"
          key="password"
          name="password"
          value={values.password}
          onChange={handleChange}
          type="password"
          placeholder="******************"
        />
      </div>

      <div className="auth-form-buttons-container">
        <button 
          type="submit" 
          className='button primary' 
          onClick={handleSignIn}
        >
          { isLoading ? 
            <i className='fas fa-rotate fa-spin' /> :
            'INICIAR SESIÓN' }
        </button>
      </div>
      
    </div>
  </div>
}
