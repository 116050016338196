import React from "react";

import { useLocation } from 'react-router-dom';

import SignIn from './SignIn';
import SignUp from './SignUp';
import ConfirmSignUp from './ConfirmSignUp';
import ForgotPassword from './ForgotPassword';

import './index.css'

export default function AuthPage(props) {

  const location = useLocation();

  React.useEffect(() => {
    let componentMounted = true;
    const fetchData = async () => {      
      if ( componentMounted ) {
        const video = document.querySelector("video");
        if (video) {
          video.playbackRate = 0.5;
        }
      }
    };
    fetchData();
    return () => componentMounted = false;
  }, []);

  let page;
  if ( location.pathname.includes('iniciar-sesion') ) {
    page = <SignIn />;
  } else if ( location.pathname.includes('registrarme') && location.hash === '#confirmar' ) {
    <ConfirmSignUp 
      onChange={this.handleChange}
      values={this.state.values}
      handleConfirmSignUp={this.handleConfirmSignUp} 
      handleResendSignUp={this.handleResendSignUp} 
    />
  } else if ( location.pathname.includes('registrarme') ) {
    <SignUp 
      onChange={this.handleChange}
      onSignUp={this.handleSignUp} 
    />
  } else if ( location.pathname.includes('olvide-mi-contraseña') ) {
    <ForgotPassword onChange={this.handleChange} />
  }

  return <div className="auth-container">
    <h1>¡Bienvenido!</h1>

    <div className='auth-video'>
      <video id="introVideo" autoPlay muted loop>
        <source src={process.env.PUBLIC_URL + "/introVideo.mp4"} type="video/mp4"/>
      </video> 
    </div>

    <div className='auth-logo-container flex'>
      <img src={process.env.PUBLIC_URL + "/logo512R.png"} alt='logo' />
    </div>

    { page }
  </div>
};
