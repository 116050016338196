import React from 'react';


// import NotFound from './components/pages/NotFound';
import { BrowserRouter, Routes, Route, Navigate, useLocation, Outlet } from 'react-router-dom';

import { Amplify, Auth, Hub } from 'aws-amplify';

import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import PageLayout from './components/PageLayout.js';
import AuthPage from './app/Authentication';
import Home from './app/index.js';
import Orders from './app/Orders';
import Analytics from './app/Analytics';
import Support from './app/Support';
import NotFound from './NotFound';

import awsconfig from './aws-exports';

import './App.css';

Amplify.configure(awsconfig);

function App() {
  
  const [mobil, setMobil] = React.useState(false)
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    let componentMounted = true;
    const fetchData = async () => {      
        
      let newMobil = false;
      if ( window.innerWidth < 666 ) newMobil = true;

      let newUser = await getSession();
      console.log('session res: ', newUser)

      if ( componentMounted ) {
        if ( newUser ) setIsLoggedIn(true)
        else setIsLoggedIn(false);
        setMobil(newMobil);
        setIsLoading(false);
      }
    };
    fetchData();
    return () => componentMounted = false;
  }, []);

  Hub.listen('auth', (data) => {
    const event = data.payload.event;
    if (event === "signIn") {
      console.log('user signed in...');
      setIsLoggedIn(true);
    } else if (event === "signOut") {
      console.log('user signed out...');
      setIsLoggedIn(false);
    }
  });

  const getSession = async () => {
    try {
      let newUser = await Auth.currentAuthenticatedUser()
      console.log('session: ', newUser)
      return true;
        
    } catch(err) {
      console.log('Error getting session: ', err);
      return false;
    };
  }

  const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    // return isLoggedIn ? children : <AuthComponent />;
    return isLoggedIn ? children : <Navigate 
      to="/iniciar-sesion" 
      replace
      state={{ path: location.pathname }}
    />;
  };

  return <BrowserRouter>      
    <Routes>

      <Route element={
        <Outlet context={{
          isLoggedIn, mobil
        }} />
      }>
        {/*PAGE LAYOUT*/}
        <Route element={<PageLayout />}>
          <Route path='/' element={ 
            <ProtectedRoute>
              <Home mobil={mobil} />
            </ProtectedRoute>
          }>
            <Route path='reportes' element={<Analytics />}/>
            <Route path='recordatorios' element={<Support />}/>
            <Route path='soporte' element={<Support />}/>
            <Route path='pedidos' element={<Orders />}/>
            <Route path='pedidos/:orderId' element={<Orders />} />
          </Route>
        </Route>
      </Route>
      
      <Route exact path='iniciar-sesion' element={<AuthPage />} />

      <Route path='404' element={<NotFound />} />    
    
    </Routes>
  </BrowserRouter>
}

export default App;
