/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateLaViaSana = /* GraphQL */ `
  subscription OnCreateLaViaSana {
    onCreateLaViaSana {
      id
      sk
      dk
      status
      date
      customer
      price_level
      product
      category
      sub_category
      unit
      vendor
      qty
      message
      context
    }
  }
`;
export const onUpdateLaViaSana = /* GraphQL */ `
  subscription OnUpdateLaViaSana {
    onUpdateLaViaSana {
      id
      sk
      dk
      status
      date
      customer
      price_level
      product
      category
      sub_category
      unit
      vendor
      qty
      message
      context
    }
  }
`;
export const onDeleteLaViaSana = /* GraphQL */ `
  subscription OnDeleteLaViaSana {
    onDeleteLaViaSana {
      id
      sk
      dk
      status
      date
      customer
      price_level
      product
      category
      sub_category
      unit
      vendor
      qty
      message
      context
      createdAt
      updatedAt
    }
  }
`;
