import React from 'react';

import { Auth } from 'aws-amplify';
import { Link, useParams } from 'react-router-dom';

import './Navbar.css';

function Navbar(props) {
  
  const { orderId } = useParams();
  
  const { mobil } = props;

  const [user, setUser] = React.useState({});
  const [username, setUsername] = React.useState('');
  // const [menu, setMenu] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {      
    Auth.currentAuthenticatedUser()
      .then(user => {        
        let newUsername = user.username.charAt(0).toUpperCase() + user.username.slice(1);

        setUser(user);
        setUsername(newUsername);
        setIsLoading(false);
      })
      .catch(err => console.log('Error query profile', err));
  }, []);

  return !isLoading && <div className='navbar'>
    <Link href='/' className='navbar-logo'>
      <img src={process.env.PUBLIC_URL + "/logo512R.png"} alt="Logo"/>
    </Link>
    
    { mobil && <div className='navbar-session'>
      { orderId 
        ? <Link
            className='button primary flex'
            to='/pedidos'
          >
            <i className='fas fa-arrow-right' />
          </Link> 
        : <button onClick={() => props.onMenu()}>
            {username}
            &nbsp;&nbsp;
            { props.menu 
                ? <i className='fas fa-times' /> 
                : <i className='fas fa-bars' />
            }
          </button>
      }
    </div> }

    { !mobil && <div className='navbar-session'>
      
      <button onClick={() => props.onMenu()}>
        {username}
        &nbsp;&nbsp;
        { props.menu 
            ? <i className='fas fa-chevron-up' /> 
            : <i className='fas fa-chevron-down' />
        }
      </button>

      { props.menu && <div className='navbar-submenu'> 
          <button>
            <i className='fas fa-envelope' />
            &nbsp;
            Contactar soporte
          </button>
          <button onClick={() => Auth.signOut()}>
            <i className='fas fa-sign-out-alt' />
            &nbsp;
            Cerrar sesión
          </button>
      </div> }
    </div> }
  </div>
}

export default Navbar;