import React from 'react';

import styled from 'styled-components';
import { motion } from 'framer-motion';

import './index.css';

const unitOptions = [
  { value: 'bolsas', label: 'Bolsas' },
  { value: 'bulto', label: 'Bulto' },
  { value: 'caja', label: 'Caja' },
  { value: 'charola', label: 'Charola' },
  { value: 'gr', label: 'gr' },
  { value: 'kg', label: 'Kg' },
  { value: 'lt', label: 'lt' },
  { value: 'mjo', label: 'Manojo' },
  { value: 'paq', label: 'Paquete' },
  { value: 'pz', label: 'Pz' },
  { value: 'tapa', label: 'Tapa' },
];

export default function FiltersItems(props) {
  
  const { mobil, filters } = props;
  
  const [open, setOpen] = React.useState(mobil ? false : true)
  const [search, setSearch] = React.useState('')

  const handleSearch = e => {
    setSearch(e.target.value);
    props.handleFilters(e)
  };

  const variants = {
    visible: { height: '100%' },
    hidden: { height: '80px' }
  };

  return <Container
    className='bg' 
    variants={variants}
    animate={mobil 
      ? ( open ? 'visible' : 'hidden' )
      : 'visible'
    }
    transition={{ ease: 'easeIn' }}
  >

    <section>
      <Header className='flex'>
        <h4>Buscar producto</h4>
        <Search>
          <div className='flex'>
            <input 
              className='input'
              type='text'
              name='customer'
              value={search}
              onChange={handleSearch}
            />
            <SearchClose 
              name='customer'
              value=''
              disabled={!search}
              onClick={handleSearch}
            >
              <i className='fas fa-times' />
            </SearchClose>
          </div>
          
          { mobil && <More
            className='button secondary'
            onClick={() => setOpen(!open)}
          >
            { open ? 
                <i className='fas fa-times' /> : 
                <i className='fas fa-filter' />
            }
          </More> }
        </Search>
      </Header>
    </section>

    <section>
      <h4>Unidad</h4>
      <div className='filters-units'>
        { unitOptions.map((item, i) => (
          <button 
            key={i}
            className={ filters.units.includes(item.value)
              ? 'filters-unit active' 
              : 'filters-unit'}
            name='units'
            value={item.value}
            onClick={props.handleFilters}
          >
            { filters.units.includes(item.value)
              ? <i className='fas fa-check-square' />
              : <i className='fas fa-square' />
            }&nbsp;
            { item.label }      
          </button> 
        ))}
      </div>
    </section>
    
  </Container>
};

const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0px 16px;
  z-index: 10;
  overflow: hidden;
  
  border-radius: inherit;

  & section {
    margin-bottom: 10px;
    padding-bottom: 20px;
    
    border-bottom: 1px solid #ccc;
    
    & h4 {
      margin-bottom: 6px;
      color: #46005D;
    }
  }

  @media (min-width: 666px) {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 20px;
    overflow: hidden;
    
    border: 2px solid #D0BDE2;
    border-radius: 20px;
  }
`;
const Header = styled.div`
  height: 80px;
  flex-direction: column;
  align-items: flex-start;
`;
const Search = styled.div`
  position: relative;
  width: 100%;

  display: grid;
  grid-template-columns: auto 40px;
  grid-gap: 4px;

  & div {
    position: relative;
    width: 100%;

    & input {
      height: 40px;
      width: 100%;
    }
  }

  @media (min-width: 666px)  {
    display: flex;
  }
`;
const SearchClose = styled.button`
  transition-duration: 0.3s;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  outline: none;
  border: none;
  border-radius: 50px;
  background-color: #222222;

  &:hover {
    background-color: #222222aa;
  }
  &:disabled {
    pointer-events: none;
    background-color: #cccccc;
  }
  & i {
    pointer-events: none;
    color: #fff;
    font-size: 11px;
  }
`;

const More = styled.button`
  transition-duration: 0.3s;
  height: 40px;
  width: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  
  outline: none;
  border: none;
  background-color: transparent;

  &:hover {
    color: #C767E5;
  }
`;