import React from 'react';

import { API } from "aws-amplify";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";

import './NewOrder.css';

export default function NewOrder(props) {
  
  const { crm, open } = props;

  const [customer, setCustomer] = React.useState('');
  const [suggestions, setSuggestions] = React.useState([]);
  const [msg, setMsg] = React.useState('');
  const [cursor, setCursor] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  const wrapperRef = React.useRef(null);

  React.useEffect(() => {
    setCustomer('')
    setSuggestions([])
    setMsg('')
    setCursor(0)
  }, [open]);
  
  React.useEffect(() => console.log('cursor: ', cursor), [cursor])

  function useOutside(ref) {
    React.useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.handleClose()
        }
      }
      
      function onKeyDown(e) {
        if (e.key === 'Escape') {
          if ( suggestions.length > 0 ) {
            setSuggestions([]);
            return
          }
          props.handleClose();

        } else if ( e.key === 'ArrowUp' ) {
          if ( suggestions.length > 0 ) {
            if ( cursor > 0 ) {
              setCursor(cursor - 1)
            }
          }

        } else if ( e.key === 'ArrowDown' ) {
          if ( suggestions.length > 0 ) {
            if ( cursor+1 < suggestions.length ) {
              setCursor(cursor + 1)
            } 
          } 
        } else if ( e.key === 'Enter' ) {
          if ( suggestions.length > 0 ) {
            setCustomer(suggestions[cursor].dk)
            setSuggestions([])
            setCursor(0)
          } 
        } 
      }

      document.addEventListener("mousedown", handleClickOutside);
      window.addEventListener('keydown', onKeyDown);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        window.removeEventListener('keydown', onKeyDown);
      };
    }, [ref, cursor, suggestions, props]);
  };
  useOutside(wrapperRef);

  const handleSearch = (value, search) => {
    value = value.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase();
    search = search.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase();
    return value.includes(search);
  };

  const handleCustomer = e => {
    setCustomer(e.target.value);
    let newSuggestions = [];
    if ( e.target.value ) {
     newSuggestions = crm.filter(c => handleSearch(c.dk, e.target.value))
    }
    setSuggestions(newSuggestions.slice(0,5));
    setCursor(0);
  };

  const handleSuggestion = e => {
    setCustomer(e.target.value);
    setSuggestions([]);
    setCursor(0);
  };

  const handleClearCustomer = e => {
    setCustomer('');
    setSuggestions([]);
    setCursor(0);
  };

  const handleNewOrder = async () => {
    try {
	    setIsLoading(true);
	    console.log('new order');

	    let data = { body: { 
	    	customer: customer, 
	    	msg: msg 
	    }}
	    let res = await API.post('laViaSanaREST', '/create-order', data)
	    console.log('new order res: ', res);

	    props.handleClose();

	    setIsLoading(false);
    
    } catch(e) {
	    setIsLoading(false);
	    console.log('Error adding order: ', e);

    }
  };

  return <AnimatePresence>
    { open && <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { delay: 0.3 } }}
    >
      <Content
        ref={wrapperRef} 
        className='bg'
        initial={{ y: -window.innerHeight }}
        animate={{ y: 0, transition: { delay: 0.3 } }}
        exit={{ y: -window.innerHeight }}
        disabled={isLoading ? 1 : 0}
      >
        <h2>Agregar nuevo pedido</h2>

        <section>
          <label>Nombre del cliente</label>
          <div className='new-order-suggestions-container'>
            <input 
            	className='input'
              type='text' 
              name='customer' 
              autoComplete="off"
              value={customer}
              placeholder='Escriba el nombre del cliente'
              onChange={handleCustomer}
            />

            { customer && <button 
              className='flex new-order-customer-clear' 
              onClick={handleClearCustomer}
            >
              <i className='fas fa-times'/>
            </button> }

            { suggestions.length > 0 && <div className='new-order-suggestions bg'>
              { suggestions.slice(0,5).map((s, i) => (
                  <button
                    className='new-order-suggestion'
                    key={i}
                    value={s.dk}
                    onClick={handleSuggestion}
                    onMouseOver={e => setCursor(i)}
                    onMouseLeave={e => setCursor(null)}
                    style={{
                      backgroundColor: i === cursor ? '#D0BDE2' : ''
                    }}
                  >
                    {s.dk}
                  </button>
              ))}
            </div> }
          </div>
        </section>
        
        <section>
          <label>Mensaje</label>
          <textarea 
            className='input textarea'
            name='msg' 
            value={msg}
            placeholder='Escriba el mensaje'
            onChange={e => setMsg(e.target.value)}
          />
        </section>
        
        <Submit className='flex'>
          <button 
            className='button primary'
            onClick={handleNewOrder}
            disabled={!customer || isLoading}
          >
          	{ isLoading 
          		? <i className='fas fa-spinner fa-spin' />
              : `AGREGAR`
            }
          </button> 
        </Submit> 
        
        <Close className='flex' onClick={props.handleClose}>
          <i className='fas fa-times'/>
        </Close>

      </Content>
    </Container> }
  </AnimatePresence>
};

const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 777;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0,0,0,0.5);
  backdrop-filter: blur(8px);
`;
const Content = styled(motion.div)`
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  position: relative;
  width: calc(100% - 32px);
  padding: 16px;

  border: 2px solid #D0BDE2;
  border-radius: 16px;

  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);  

  & h2 {
    position: relative;
    margin: 0;
    margin-top: 10px;

    text-align: left;
    color: #fff;
    color: rgb(30,30,30);
    font-size: 24px;
    font-family: 'PT Sans', sans-serif;
  }

  & section {
    text-align: left;
    margin-top: 20px;

    & input {
      margin-top: 4px;
      width: 100%;
    }
    & textarea {
      margin-top: 4px;
      width: 100%;
    }
  }

  @media (min-width: 666px)  {
    width: 40vw;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);  
  }
`;

const Submit = styled.div`
  margin-top: 16px;
  justify-content: flex-end;
  
  & button {
    height: 40px;
    padding: 0px 16px;

    &:disabled {
      background-color: #ccc;
      color: #fff;
    }
  }
`;

const Close = styled.button`
  transition-duration: 0.3s;
  cursor: default;
  position: absolute;
  right: 0;
  top: 0;
  margin: 16px;
  height: 18px;
  width: 18px;

  border: none;
  outline: none;
  background-color: transparent;

  color: #aaa;
  font-size: 1rem;

  &:active {
    color: red;
  }
`;