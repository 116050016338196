import React, { useState, useEffect, useRef } from 'react';

import './SelectProduct.css';

function cleanString(str) {
  if ( str ) {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .trim()
  }
}

function SelectProduct(props) {
  
  const [showList, setShowList] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [isFocus, setIsFocus] = useState(false);
  const [addProduct, setAddProduct] = useState(true);

  useEffect(() => {
    if ( props.options ) {
      var exactMatch = props.options
        .filter(item => item.value === cleanString(props.value))
      if ( exactMatch.length > 0 ) {
        setAddProduct(false)
      } else {
        setAddProduct(true)
      }
    }
  }, [props.value])

  const items = [];
  if ( props.options && props.vendor === 'laviasana' ) {

    props.options
      .filter(item => item.value.includes(cleanString(props.value)))
      .map((item, i) => {
        if ( addProduct ) {
          i = i+1;
        }
        items.push(
          <button 
            index={i}
            key={`select-p-item-${i}`}
            className={item.label === props.value ? 
              'select-p-item active' : ( i === cursor ? 
                'select-p-item-hover' : 'select-p-item' )
            }
            id={props.id}
            name={props.name}
            value={item.label}
            onClick={async e => {
              // setValue(e.target.value);
              props.onChange(e.target.value);
              setShowList(false);
            }}
            onMouseEnter={e => setCursor(
              parseInt(e.target.getAttribute('index')) 
            )}
            onMouseLeave={e => setCursor(null)}
          >
            {item.label}
          </button>
        )
      })
  }

  function useOutside(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // console.log(ref.current, event.target);
          setShowList(false);
        }
      }
      
      function onKeyDown(e) {
        if (e.key === 'Escape') {
          setShowList(false);
        } else if ( e.key === 'ArrowUp' ) {
          if ( showList && cursor > 0 ) {
            setCursor(cursor - 1)
          }
        } else if ( e.key === 'ArrowDown' ) {
          if ( showList && cursor < items.length ) {
            setCursor(cursor + 1)
          } 
        } else if ( e.key === 'Enter' ) {
            if ( showList ) {
              if ( addProduct ) {
                if ( cursor === 0 ) {
                  setShowList(false);
                  return
                }
                var newCursor = cursor-1;
              } else {
                var newCursor = cursor;
              }

              var newValue = props.options
                .filter(item => item.value
                  .includes(cleanString(props.value)))[newCursor]
              
              props.onChange(newValue.label)
              setShowList(false);
            }
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      window.addEventListener('keydown', onKeyDown);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        window.removeEventListener('keydown', onKeyDown);
      };
    }, [ref, showList, cursor, props]);
  }

  const menuRef = useRef(null);
  useOutside(menuRef);

  return <div 
    ref={menuRef} 
    className={ props.vendor === 'notas'
      ? 'select-p active'
      : 'select-p' }
  >      
    { props.vendor === 'notas' ? <textarea 
        className='selected-p-note'
        autoComplete='off'
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={async e => {
          props.onChange(e.target.value);
        }}
      /> : <input 
        type='text'
        className='input'
        autoComplete='off'
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={async e => {
          props.onChange(e.target.value);
          setCursor(0);
          if ( props.vendor === 'laviasana' ) {
            setShowList(true);
          }
        }}
        onClick={async e => {
          if ( props.vendor === 'laviasana' ) {
            setShowList(!showList)
          }
        }}
      />}

    { showList && <div 
      className='select-p-items'
      style={{ 
        top: props.isUp ? '' : '40px', 
        bottom: props.isUp ? '40px' : ''
      }}
    >
      { addProduct && <button 
          index={0}
          key={`select-p-item-0`}
          className={ cursor === 0 ? 
            'select-p-item-hover' : 'select-p-item'
          }
          id={props.id}
          name={props.name}
          value={props.value}
          onClick={e => {
            // setValue(e.target.value);
            props.onChange(e);
            setShowList(false);
          }}
          onMouseEnter={e => setCursor(
            parseInt(e.target.getAttribute('index')) 
          )}
          onMouseLeave={e => setCursor(null)}
        >
          {`++ ${props.value}`}
        </button>
      }

      { items }
    </div> }
  </div>

}

export default SelectProduct;