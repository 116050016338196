import React from 'react';

import Chart from './Chart.js';

import { Link } from 'react-router-dom';

import './index.css';

export default function Analytics(props) {
    
    return <div className="data">
        <div className="widgets">
          
          <div to="/users" className="widget">
              <div className="left">
                  <span className="title">Clientes</span>
                  <span className="counter">789</span>
                  <Link 
                      to="/users" 
                      className="link">
                          See all users
                  </Link>
              </div>
              <div className="right">
                  <div className="percentage positive">
                      <i className='fas fa-arrow-up' />
                      100%
                  </div>
                  <i className='fas fa-user' style={{ color:"crimson" }}/>
              </div>
          </div>

          <div className="widget">
                <div className="left">
                    <span className="title">Usuarios los últimos 30 días</span>
                    <span className="counter">599</span>
                    {/*<span className="link">See all users</span>*/}
                </div>

                <div className="right">
                    <div className="percentage positive">
                        <i className='fas fa-arrow-up' />100%
                    </div>
                    <i className='fas fa-user' style={{ color:"crimson" }}/>
                </div>
            </div>

          <div className="widget">
              <div className="left">
                  <span className="title">Ordenes</span>
                  <span className="counter">13,063</span>
                  {/*<span className="link">See all users</span>*/}
              </div>
              <div className="right">
                  <div className="percentage positive">
                      <i className='fas fa-arrow-up' /> 100%
                  </div>
                  <i className='fas fa-user' style={{ color:"crimson" }}/>
              </div>
          </div>

          <div className="widget">
              <div className="left">
                  <span className="title">Ordenes últimos 30 días</span>
                  <span className="counter">699</span>
                  {/*<span className="link">See all users</span>*/}
              </div>
              <div className="right">
                  <div className="percentage positive">
                      <i className='fas fa-arrow-up' /> 100%
                  </div>
                  <i className='fas fa-user' style={{ color:"crimson" }}/>
              </div>
          </div>

        </div>
      
        <div className="charts">
            <Chart title="Last 6 Months (Revenue)" aspect={14 / 5} />
        </div>
    </div>
};