/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const laViaSanaChatbot = /* GraphQL */ `
  mutation LaViaSanaChatbot($input: chatInput) {
    laViaSanaChatbot(input: $input)
  }
`;
export const createLaViaSana = /* GraphQL */ `
  mutation CreateLaViaSana(
    $input: CreateLaViaSanaInput!
    $condition: ModellaViaSanaConditionInput
  ) {
    createLaViaSana(input: $input, condition: $condition) {
      id
      sk
      dk
      status
      date
      customer
      price_level
      product
      category
      sub_category
      unit
      vendor
      qty
      message
      context
      pz
      kg
      gr
      lt
      paq
      charola
      mjo
      tapa
      caja
      bulto
      bolsa
      iva
      costo
      margen_a
      precio_a
      margen_b
      precio_b
      margen_c
      precio_c
      address
      latitud
      longitud
      createdAt
      updatedAt
    }
  }
`;
export const updateLaViaSana = /* GraphQL */ `
  mutation UpdateLaViaSana(
    $input: UpdateLaViaSanaInput!
    $condition: ModellaViaSanaConditionInput
  ) {
    updateLaViaSana(input: $input, condition: $condition) {
      id
      sk
      dk
      status
      date
      customer
      price_level
      product
      category
      sub_category
      unit
      vendor
      qty
      message
      context
      pz
      kg
      gr
      lt
      paq
      charola
      mjo
      tapa
      caja
      bulto
      bolsa
      iva
      costo
      margen_a
      precio_a
      margen_b
      precio_b
      margen_c
      precio_c
      address
      latitud
      longitud
      createdAt
      updatedAt
    }
  }
`;
export const deleteLaViaSana = /* GraphQL */ `
  mutation DeleteLaViaSana(
    $input: DeleteLaViaSanaInput!
    $condition: ModellaViaSanaConditionInput
  ) {
    deleteLaViaSana(input: $input, condition: $condition) {
      id
      sk
      dk
      status
      date
      customer
      price_level
      product
      category
      sub_category
      unit
      vendor
      qty
      message
      context
      pz
      kg
      gr
      lt
      paq
      charola
      mjo
      tapa
      caja
      bulto
      bolsa
      iva
      costo
      margen_a
      precio_a
      margen_b
      precio_b
      margen_c
      precio_c
      address
      latitud
      longitud
      createdAt
      updatedAt
    }
  }
`;

export const createOrder = /* GraphQL */ `
  mutation CreateLaViaSana(
    $input: CreateLaViaSanaInput!
    $condition: ModellaViaSanaConditionInput
  ) {
    createLaViaSana(input: $input, condition: $condition) {
      id
      sk
      dk
      date
      customer
      message
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateLaViaSana(
    $input: UpdateLaViaSanaInput!
    $condition: ModellaViaSanaConditionInput
  ) {
    updateLaViaSana(input: $input, condition: $condition) {
      id
      sk
      dk
      date
      customer
      message
    }
  }
`;

export const createUser = /* GraphQL */ `
  mutation CreateLaViaSana(
    $input: CreateLaViaSanaInput!
    $condition: ModellaViaSanaConditionInput
  ) {
    createLaViaSana(input: $input, condition: $condition) {
      id
      sk
      dk
      date
      address
      latitud
      longitud
      precio_a
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateLaViaSana(
    $input: UpdateLaViaSanaInput!
    $condition: ModellaViaSanaConditionInput
  ) {
    updateLaViaSana(input: $input, condition: $condition) {
      id
      sk
      dk
      date
      address
      latitud
      longitud
      precio_a
    }
  }
`;

export const createProduct = /* GraphQL */ `
  mutation CreateLaViaSana(
    $input: CreateLaViaSanaInput!
    $condition: ModellaViaSanaConditionInput
  ) {
    createLaViaSana(input: $input, condition: $condition) {
      id
      sk
      dk
      product
      category
      sub_category
      unit
      vendor
      qty
      message
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateLaViaSana(
    $input: UpdateLaViaSanaInput!
    $condition: ModellaViaSanaConditionInput
  ) {
    updateLaViaSana(input: $input, condition: $condition) {
      id
      sk
      dk
      product
      category
      sub_category
      unit
      vendor
      qty
      message
    }
  }
`;

export const createCatalog = /* GraphQL */ `
  mutation CreateLaViaSana(
    $input: CreateLaViaSanaInput!
    $condition: ModellaViaSanaConditionInput
  ) {
    createLaViaSana(input: $input, condition: $condition) {
      id
      sk
      dk
      product
      category
      sub_category
      unit
      pz
      kg
      gr
      lt
      paq
      charola
      mjo
      tapa
      caja
      bulto
      bolsa
      costo
      iva
      margen_a
      precio_a
      margen_b
      precio_b
      margen_c
      precio_c
    }
  }
`;
export const updateCatalog = /* GraphQL */ `
  mutation UpdateLaViaSana(
    $input: UpdateLaViaSanaInput!
    $condition: ModellaViaSanaConditionInput
  ) {
    updateLaViaSana(input: $input, condition: $condition) {
      id
      sk
      dk
      product
      category
      sub_category
      unit
      pz
      kg
      gr
      lt
      paq
      charola
      mjo
      tapa
      caja
      bulto
      bolsa
      costo
      iva
      margen_a
      precio_a
      margen_b
      precio_b
      margen_c
      precio_c
    }
  }
`;

export const createNote = /* GraphQL */ `
  mutation CreateLaViaSana(
    $input: CreateLaViaSanaInput!
    $condition: ModellaViaSanaConditionInput
  ) {
    createLaViaSana(input: $input, condition: $condition) {
      id
      sk
      dk
      product
      vendor
      message
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateLaViaSana(
    $input: UpdateLaViaSanaInput!
    $condition: ModellaViaSanaConditionInput
  ) {
    updateLaViaSana(input: $input, condition: $condition) {
      id
      sk
      dk
      product
      vendor
      message
    }
  }
`;

export const createCache = /* GraphQL */ `
  mutation CreateLaViaSana(
    $input: CreateLaViaSanaInput!
    $condition: ModellaViaSanaConditionInput
  ) {
    createLaViaSana(input: $input, condition: $condition) {
      id
      sk
      dk
      date
      message
    }
  }
`;
export const updateCache = /* GraphQL */ `
  mutation UpdateLaViaSana(
    $input: UpdateLaViaSanaInput!
    $condition: ModellaViaSanaConditionInput
  ) {
    updateLaViaSana(input: $input, condition: $condition) {
      id
      sk
      dk
      date
      message
    }
  }
`;

export const createProgress = /* GraphQL */ `
  mutation CreateLaViaSana(
    $input: CreateLaViaSanaInput!
    $condition: ModellaViaSanaConditionInput
  ) {
    createLaViaSana(input: $input, condition: $condition) {
      id
      sk
      dk
      date
    }
  }
`;
export const updateProgress = /* GraphQL */ `
  mutation UpdateLaViaSana(
    $input: UpdateLaViaSanaInput!
    $condition: ModellaViaSanaConditionInput
  ) {
    updateLaViaSana(input: $input, condition: $condition) {
      id
      sk
      dk
      date
    }
  }
`;
