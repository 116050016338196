import React, { useState, useEffect, useRef } from 'react';

import { API } from "aws-amplify";
import * as mutations from '../graphql/_mutations';
import * as queries from '../graphql/_queries';

import './SelectVendorAdd.css';

function SelectVendorAdd(props) {
  
  const [showList, setShowList] = useState(false);
  const [cursor, setCursor] = useState(0);

  const wrapperRef = useRef(null);

  function useOutside(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // console.log(ref.current, event.target);
          setShowList(false);
        }
      }
      
      function onKeyDown(e) {
        if (e.key === 'Escape') {
          setShowList(false);
        } else if ( e.key === 'ArrowUp' ) {
          if ( showList && cursor > 0 ) {
            setCursor(cursor - 1)
          }
        } else if ( e.key === 'ArrowDown' ) {
          if ( showList && cursor < props.options.length ) {
            setCursor(cursor + 1);
          } 
        } else if ( e.key === 'Enter' ) {
          if ( showList ) {
            var newValue = props.options[cursor];
            props.onChange(newValue.value);
            setShowList(false);
          }
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      window.addEventListener('keydown', onKeyDown);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        window.removeEventListener('keydown', onKeyDown);
      };
    }, [ref, showList, cursor, props]);
  }
  useOutside(wrapperRef);

  const handleChange = async e => {
    props.onChange(e)
    setShowList(false);
  }

  const label = () => {
    var l = props.options
      .filter(item => item.value === props.value)

    if ( l.length > 0 ) {
      return l[0].label
    } else {
      return ''
    }
  }

  return <div ref={wrapperRef} className='select-v-add'>  
    
    <button 
      className={ showList ? 'button secondary active' : 'button secondary' }
      onClick={() => setShowList(!showList)}
    >
      { label() }
    </button>

    { showList && <ul className='bg select-v-add-items'>
      { props.options.map((item, i) => (
          <button 
            index={i}
            key={`select-v-add-item-${i}`}
            className={item.value === props.value
              ? 'select-v-add-item active' 
              : 'select-v-add-item'
            }
            name={props.name}
            style={{ backgroundColor: i === cursor ? '#D0BDE2' : '' }}
            value={item.value}
            label={item.label}
            onClick={handleChange}
            onMouseEnter={e => setCursor(
              parseInt(e.target.getAttribute('index')) 
            )}
            onMouseLeave={e => setCursor(null)}
          >
            {item.label}
          </button>
      ))}
     </ul> }
  </div>
}

export default SelectVendorAdd;
