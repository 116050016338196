import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { AnimatePresence, motion } from "framer-motion";
import { Link, useNavigate, useParams, useOutletContext } from "react-router-dom";

import { API } from "aws-amplify";
import * as mutations from 'graphql/_mutations';
import * as queries from 'graphql/_queries';
import * as subscriptions from 'graphql/_subscriptions';

import Item from './Item'
import AddItem from './AddItem';
import EditItem from './EditItem';
import DeleteItem from './DeleteItem';
import FiltersItems from './FiltersItems';

import './index.css';

var statuses = [
  {value: 'open', label: 'Abierta'},
  {value: 'converted', label: 'Convertida'},
  {value: 'validated', label: 'Validada'},
  {value: 'consolidated', label: 'Consolidada'}
];
var vendorsOr = ['notas', 'laviasana', 'costco', 'heb', 'ramos'];            

export default function Order(props) {

  const { orderId } = useParams();
  const { 
    mobil, catalog, productOptions, handleStatus
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  
  const [order, setOrder] = useState(null);
  const [list, setList] = useState([]);
  const [filters, setFilters] = useState({ search: '', units: [] });
  const [isFilters, setIsFilters] = useState(false);
  const [addItem, setAddItem] = useState(false);
  const [edit, setEdit] = useState(null);
  const [deleteItem, setDeleteItem] = useState({ isDeleting: true, id: '' });
  
  const [subscription, setSubscription] = useState({ type: null, data: null });

  const navigate = useNavigate();

  React.useEffect(() => {
    let componentMounted = true;
    const fetchData = async () => {
      
      var newOrder = await getOrder(orderId);
      var newList = await getList(orderId);
      console.log('list: ', newList);
      
      if(componentMounted) {        
        setOrder(newOrder);
        setList(newList);
        setIsLoading(false);
      }
    };
    
    console.log('order id: ', orderId);
    console.log('order: ', order);
    if ( !orderId ) return;
    setIsLoading(true);
    setOrder(null);
    setList([]);
    fetchData();
    return () => componentMounted = false;
  }, [orderId]);

  // Subscriptions
  React.useEffect(() => {
    let componentMounted = true;
    
    const subscribeCreates = API.graphql({
      query: subscriptions.onCreateLaViaSana
    }).subscribe({
      next: ({ provider, value }) => {
        var data = value.data.onCreateLaViaSana;
        console.log('onCreate: ', data);
        if ( data !== null ) {
          setSubscription({ type: 'onCreate', data: data });
        }
      },
      error: error => console.warn(error)
    });

    const subscribeUpdates = API.graphql({
      query: subscriptions.onUpdateLaViaSana
    }).subscribe({
      next: ({ provider, value }) => {
        var data = value.data.onUpdateLaViaSana;
        console.log('onUpdate: ', data);
        if ( data !== null ) {
          setSubscription({ type: 'onUpdate', data: data });
        }
      },
      error: error => console.warn(error)
    });

    const subscribeDeletes = API.graphql({
      query: subscriptions.onDeleteLaViaSana
    }).subscribe({
      next: ({ provider, value }) => {
        var data = value.data.onDeleteLaViaSana;
        console.log('onDelete: ', data);
        if ( data !== null ) {
          setSubscription({ type: 'onDelete', data: data });
        }
      },
      error: error => console.warn(error)
    });

    return () => {
     componentMounted = false;
     subscribeCreates.unsubscribe();
     subscribeUpdates.unsubscribe();
     subscribeDeletes.unsubscribe();
    }
  }, []);

  React.useEffect(() => {
    const { type, data } = subscription;
    if ( data !== null ) {
      processSubscription(type, data);
      setSubscription({ type: null, data: null })
    }
  }, [subscription]);

  const processSubscription = (type, data) => {
    if ( data.sk === 'ORDER' ) {
      if ( type === 'onUpdate' ) {
        console.log('Update ORDER: ', data.id, data.dk);
        if ( orderId ) setOrder(data);
      }
    } else {
      console.log('other subscription');
    }
  };

  function cleanString(str) {
    if ( ![null, undefined].includes(str) ) {
      return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/ +(?= )/g,'')
        .replace(/[,.]/g,'')
        .toLowerCase()
        .trim();
    }
    return str;
  };

  function formatDate(date) {
    var newDate = new Date(date)
    var days = {
      0: 'Lunes', 1: 'Martes', 2: 'Miércoles', 3: 'Jueves',
      4: 'Viernes', 5: 'Sábado', 6: 'Domingo'
    }

    var months = {
      0: 'enero', 1: 'febrero', 2: 'marzo', 3: 'abril',
      4: 'mayo', 5: 'junio', 6: 'julio', 7: 'agosto',
      8: 'septiembre', 9: 'octubre', 10: 'noviembre', 11: 'diciembre'
    }
    
    return days[newDate.getDay()]+', '+
      newDate.getUTCDate()+' de '+
      months[newDate.getMonth()]
      // newDate.getFullYear()
  };
  
  function statusLabel(value) {
    return statuses.filter(x => x.value === value)[0].label
  };

  const getOrder = async orderId => {
    try {
      let res = await API.graphql({ 
        query: queries.getOrder, 
        variables: { 
          id: orderId,
          sk: 'ORDER'
        }
      });
      let key = Object.keys(res.data)[0]
      return res.data[key];

    } catch (error) {
      console.log('Error getting order: ', error);
    }
  };

  const getList = async id => {  
    try {
      console.log('get list: ', id);
      let newItems = [];
      let params = { 
        query: queries.gs1LIST, 
        variables: { 
          sk: 'ITEM',
          dk: { eq: id },
        }
      };
      console.log('get list req: ', params);
      let res = await API.graphql(params);
      console.log('get list res: ', res);
      let key = Object.keys(res.data)[0];
      res = res.data[key];
      newItems = newItems.concat(res.items);

      while ( res.nextToken ) {
        params.nextToken = res.nextToken;
        console.log('get token req: ', params);
        res = await API.graphql(params);
        console.log('get token res: ', res);
        res = res.data[key];
        newItems = newItems.concat(res.items);
      }
      console.log('list res: ', newItems);

      return newItems;

    } catch (error) {
      console.log('error: ', error);
    }
  };


  const handleSearch = (value, search) => {
    value = value.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase();
    search = search.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase();
    return value.includes(search);
  };

  const handleFilters = async e => {
    if ( e.target.name === 'units' ) {
      let newUnits;
      if ( filters.units.includes(e.target.value) ) {
        newUnits = [ ...filters.units ].filter(u => u !== e.target.value);
      } else {
        newUnits = [ ...filters.units ].concat(e.target.value);
      }
      setFilters({ ...filters, 'units': newUnits });
      return;
    };

    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleUpdateOrder = values => {
    var newList = list.map(l => {
      if ( l.id === values.id ) return values;
      return l
    });
    setList(newList);
  };

  const handleAddItem = async values => {
    
    if ( !addItem ) {
      setAddItem(true);
      return
    }
    
    console.log('add item: ', values);

    try {
      // Add product to backEnd
      if ( values.vendor === 'notas' ) {
        delete values.qty;
        delete values.unit;
        
        values['id'] = 'i'+new Date().getTime().toString();
        values['sk'] = 'ITEM';
        values['dk'] = props.order.id;
        values['message'] = values.product;
        
        const response = await API.graphql({ 
          query: mutations.createNote, 
          variables: {input: values}
        });
        console.log(response);
      } else {
        var newProduct = props.catalog
          .filter(item => item.producto === values.product)
        
        values['id'] = 'i'+new Date().getTime().toString();
        values['sk'] = 'ITEM';
        values['dk'] = props.order.id;
        values['qty'] = parseFloat(values.qty);
        values['sub_category'] = newProduct.length > 0 ? newProduct[0]['sub_categoria'] : '-';
        values['category'] = newProduct.length > 0 ? newProduct[0]['categoria'] : '-';
        values['message'] = '';
       
        const response = await API.graphql({ 
          query: mutations.createProduct, 
          variables: {input: values}
        });
        console.log(response);
      }

      setList([ ...list, values]);
      setAddItem(false);

    } catch(e) {
      console.log('Error adding item: ', e)
    }
  };

  const handleEdit = newValues => {
    setEdit(newValues)
  };

  const handleDeleteItem = async e => {
    try {
      if ( e.target.name === 'deleteProduct' ) {
        setDeleteItem({ isDeleting: true, id: e.target.value });
      
      } else if ( e.target.name === 'confirm' ) {      
        // Delete in backEnd
        var data = list.filter(o => o.id === deleteItem.id);

        let res = await API.graphql({ 
          query: mutations.deleteLaViaSana, 
          variables: { input: { 
            id: data[0].id,
            sk: data[0].sk,
          }}
        })
        res = res.data.deleteLaViaSana;
        console.log('onDelete: ', res);
        
        let newList = list.filter(o => o.id !== deleteItem.id);
        setList(newList);
        setDeleteItem({ isDeleting: false, id: '' });

      } else if ( e.target.name === 'cancel' ) {
        setDeleteItem({ isDeleting: false, id: '' });
      }
    
    } catch(error) {
      console.log('Error deleting item: ', error);
    }
  };

  const handleCloseOrder = () => {
    navigate('/pedidos');
  };

  // COMPONENTS
  const Status = () => {
    if ( order.dk === 'open' ) {
      return <div className='order-summary-status'>
        <button
          className='button secondary' 
          disabled={true}
        >
          {statusLabel(order.dk)}
        </button>
      </div>
    } else {
      return <div className='order-summary-status'>
        <button
          className={order.dk === 'converted' 
            ? 'button secondary active' 
            : 'button secondary' }
          name={order.id}
          value='converted'
          onClick={handleStatus}
        >
          {statusLabel('converted')}
        </button>
        <button
          className={order.dk === 'validated' 
            ? 'button secondary active' 
            : 'button secondary' }
          name={order.id}
          value='validated'
          onClick={handleStatus}
        >
          {statusLabel('validated')}
        </button>
      </div>
    }
  };

  // .sort((a, b) => a.product.localeCompare(b.product))
  return <AnimatePresence>
    { orderId && <Container 
      className='bg'
      initial={{ x: window.innerWidth }}
      animate={{ x: 0 }}
      exit={{ x: window.innerWidth }}
      transition={{ ease: 'easeOut' }}
    >
      { isLoading && <Loading className='flex'>
        <img src={process.env.PUBLIC_URL + '/gifs/fruits_gif5.gif'} className='loading'/>
        <h2>Cargando...</h2>
      </Loading> }

      <Content>
        { !mobil && <FiltersItems 
          mobil={mobil}
          isFilters={isFilters}
          filters={filters}
          handleFilters={handleFilters}
          handleClose={() => setIsFilters(!isFilters)}
        /> }

        <ItemList> 
          {/*Summary*/}
          <div className='order-summary'>
            { order && (
                mobil 
                ? <div className='order-summary-data'>
                    <div>
                      <h5>
                        {order.customer}
                        ,&nbsp;
                        {formatDate(order.date.slice(0,10))}
                      </h5>
                    </div>
                    <Status/>
                  </div> 
                : <div className='order-summary-data'>
                    <div>
                      <h5>
                        Cliente:&nbsp;
                        <span>{order.customer}</span>
                      </h5>
                    </div>
                    <div>
                      <h5>
                        Fecha:&nbsp;
                        <span>
                          {formatDate(order.date.slice(0,10))}
                        </span>
                      </h5>
                    </div>
                  </div> 
            )}
            
            <div className='order-summary-action'>
              { !mobil && order && <Status/> }

              <button 
                className='button secondary'
                name='newProduct' 
                onClick={handleAddItem}
                style={{
                  padding: mobil ? '' : '0px 16px 0px 12px'
                }}
              >
                { mobil
                  ? <i className='fas fa-plus' />
                  : <>
                      <i className='fas fa-plus' />
                      &nbsp;
                      <span>Producto</span>
                    </>
                }
              </button>
              { !mobil && <Link
                className='button primary flex'
                to='/pedidos'
              >
                <i className='fas fa-arrow-right' />
              </Link> }
            </div>
          </div>

          <div className='order-list-items'>
            <div>
              { list
                .filter(l => !filters.search || filters.search && (
                    l.product && handleSearch(l.product, filters.search) 
                    || ( l.vendor && handleSearch(l.vendor, filters.search) )
                    || ( l.category && handleSearch(l.category, filters.search) )
                    || ( l.sub_category && handleSearch(l.sub_category, filters.search) )
                  )
                )
                .filter(l => !filters.units.length || filters.units.includes(l.unit))
                .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
                .sort((a, b) => vendorsOr.indexOf(a.vendor) - vendorsOr.indexOf(b.vendor))
                .map((item, i) => <Item
                  key={item.id}
                  index={i}
                  mobil={mobil}
                  values={item} 
                  filters={filters}
                  items={list.length}
                  catalog={catalog}
                  productOptions={productOptions}
                  handleEdit={handleEdit}
                  handleUpdateOrder={handleUpdateOrder}
                  handleDelete={handleDeleteItem}
                /> )
              }
            </div>
          </div>
        </ItemList>
      </Content>

      {/*Add Item*/}
      <AddItem 
        open={addItem}
        list={list}
        productOptions={productOptions}
        handleAddProduct={handleAddItem} 
        handleClose={() => setAddItem(false)}
      /> 
      
      {/*Edit Item*/}
      <EditItem 
        values={edit} 
        productOptions={productOptions}
        catalog={catalog}
        onCancel={() => setEdit(null)}
        handleUpdateOrder={handleUpdateOrder}
      />

      {/*Delete Item*/}
      { deleteItem.isDeleting && <DeleteItem 
        list={list}
        deleteProduct={deleteItem}
        handleDeleteProduct={handleDeleteItem}
      /> }
    
    </Container> }
  </AnimatePresence>
};

const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  @media (min-width: 666px)  {
    height: calc(100vh - 140px);
    padding: 20px;
  }
`;

const Content = styled(motion.div)`
  height: 100%;
  width: 100%;

  @media (min-width: 666px)  {    
    display: grid;
    grid-template-columns: 340px auto;
    grid-gap: 20px;
  }
`;

const ItemList = styled(motion.div)`
  position: relative;
  height: 100%;
  overflow: hidden;

  @media (min-width: 666px)  {
    height: 100%;
    width: 100%;
    padding: 0px 20px;
    padding-bottom: 10px;

    display: grid;
    grid-template-rows: 60px calc(100% - 60px);
    
    border: 2px solid #D0BDE2;
    border-radius: 20px;
  }
`;

const Loading = styled.div`
  height: 100%;
  flex-direction: column;

  & h2 {
    height: 50px;
    font-family: 'PT Sans', sans-serif;
  }
  & img {
    width: calc(100% - 40px);
    height: auto;
    object-fit: contain;
  }
  @media (min-width: 666px)  {
    margin-bottom: 50px;
    & img {
      width: auto;
      height: 300px;
    } 
  }
`;
