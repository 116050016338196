import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';

import { API } from "aws-amplify";
import * as mutations from 'graphql/_mutations';

import SelectUnitAdd from 'components/SelectUnitAdd'
import SelectProductAdd from 'components/SelectProductAdd'
import SelectVendorAdd from 'components/SelectVendorAdd'

import './EditItem.css';

const unitOptions = [
  { value: 'pz', label: 'Pz' },
  { value: 'kg', label: 'Kg' },
  { value: 'gr', label: 'gr' },
  { value: 'lt', label: 'lt' },
  { value: 'paq', label: 'Paquete' },
  { value: 'charola', label: 'Charola' },
  { value: 'mjo', label: 'Manojo' },
  { value: 'tapa', label: 'Tapa' },
  { value: 'caja', label: 'Caja' },
  { value: 'bolsa', label: 'Bolsas' },
  { value: 'bulto', label: 'Bulto' }
]

const vendorOptions = [
  { value: 'notas', label: 'Notas' },
  { value: 'laviasana', label: 'La Vía Sana' },
  { value: 'costco', label: 'Costco' },
  { value: 'heb', label: 'HEB' },
  { value: 'ramos', label: 'Ramos' },
  { value: 'sams', label: 'SAMS' },
  { value: 'bodega', label: 'En Bodega' },
  { value: 'san_juan', label: 'San Juan' },
  { value: 'otros', label: 'Otros' },
];

function cleanString(str) {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/ +(?= )/g,'')
    .replace(/[,.]/g,'')
    .toLowerCase()
    .trim()
};

function EditItem(props) {

  const [values, setValues] = useState(props.values)
  const [isUpdating, setIsUpdating] = useState(false)

  const wrapperRef = React.useRef();

  React.useEffect(() => {
    console.log('newValues: ', values)
    setValues(props.values);
  }, [props.values]);

  function useOutside(ref) {
    React.useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.onCancel()
        }
      }
      
      function onKeyDown(e) {
        if (e.key === 'Escape') {
          props.onCancel()
        } else if ( e.key === 'Enter' ) {
          handleSubmit()
        } 
      }

      document.addEventListener("mousedown", handleClickOutside);
      window.addEventListener('keydown', onKeyDown);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        window.removeEventListener('keydown', onKeyDown);
      };
    }, [ref, props, isUpdating]);
  };
  useOutside(wrapperRef);

  const handleProduct = async e => {
    var prod = props.catalog
      .filter(item => cleanString(item.product) === cleanString(e));
    
    var sub_category = '-';
    var category = '-';

    if ( prod.length > 0 ) {
      sub_category = prod[0]['sub_category'];
      category = prod[0]['category'];
    } 
  
    setValues({ ...values, 
      product: e,
      sub_category: sub_category,
      category: category
    });
  };

  const handleSubmit = async () => {
    try {
      setIsUpdating(true);
      const toUpdate = Object.assign({}, values);
      delete toUpdate.createdAt;
      delete toUpdate.updatedAt;
      const mutation = values.vendor === 'notas'
        ? mutations.updateNote
        : mutations.updateProduct;
      let res = await API.graphql({ 
        query: mutation, 
        variables: { input: toUpdate }
      });
      console.log('update item res: ', res);
      await props.handleUpdateOrder(values)
      setIsUpdating(false);

      props.onCancel();

    } catch(err) {
      setIsUpdating(false);
      console.log('Error updating item: ', err);
    };
  };

  return <AnimatePresence>
    { values && <Container 
      className='flex'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { delay: 0.3 } }}
    >
      <motion.div 
        ref={wrapperRef}
        className='bg edit-item-container'
        initial={{ y: -window.innerHeight, opacity: 0 }}
        animate={{ y: 0, opacity: 1, transition: { delay: 0.3 } }}
        exit={{ y: -window.innerHeight, opacity: 0 }}
      >
        <h2>Editar producto</h2>
          
        <div className='edit-item-data'>
          { values.vendor !== 'notas' ? <div className='edit-item-input'>
            <p>Cantidad</p>
            <input 
              className='input'
              type='number' 
              name='qty' 
              value={values.qty}
              onChange={e => setValues({ ...values, qty: e.target.value })}
            />
          </div> : <div className='orders-add-product-input'/> }

          { values.vendor !== 'notas' ? <div className='edit-item-input'>
            <p>Unidad</p>
            <SelectUnitAdd 
              value={values.unit} 
              name='unit'
              options={unitOptions} 
              onChange={e => setValues({ ...values, unit: e.target.value })}
            />
          </div> : <div className='orders-add-product-input'/> }

          <div className='edit-item-input'>
            <p>Producto</p>
            <SelectProductAdd
              value={values.product} 
              name='value'
              vendor={values.vendor}
              options={props.productOptions} 
              onChange={handleProduct}
            />
          </div>

          <div className='edit-item-input'>
            <p>Vendor</p>
            <SelectVendorAdd
              value={values.vendor} 
              name='vendor'
              options={vendorOptions} 
              onChange={e => setValues({ ...values, vendor: e.target.value })}
            />
          </div>
        </div>
        <br/>
        
        <Submit className='flex'>
          <button 
            className='button secondary'
            onClick={props.onCancel}
          >
            CANCELAR
          </button>

          <button 
            className='button primary'
            onClick={handleSubmit}
          >
            { isUpdating 
              ? <i className='fas fa-spinner fa-spin' />
              : 'ACTUALIZAR'
            }
          </button>
        </Submit>

        {/*<Close onClick={props.onCancel}>
          <i className='fas fa-times' />
        </Close>*/}
          
      </motion.div>
    </Container> }
  </AnimatePresence>
}

const Container = styled(motion.div)`
  position: fixed;
  z-index: 888;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  background: rgba(0,0,0,0.5);
  backdrop-filter: blur(8px);
`;
const Submit = styled.div`
  justify-content: flex-end;
  grid-gap: 8px;

  & button {
    height: 40px;
    padding: 0px 16px;
  }
`;
const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 16px;

  outline: none;
  border: none;
  background: transparent;

  font-size: 1rem;
  color: #222;
  
  &:active {
    color: #C767E5;
  }
`;

export default EditItem;
