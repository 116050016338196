import React, { useState, useEffect, useRef } from 'react';

import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';

import { API, graphqlOperation } from "aws-amplify";
import * as mutations from 'graphql/_mutations';
import * as queries from 'graphql/_queries';
import * as subscriptions from 'graphql/_subscriptions';

import FiltersOrders from './FiltersOrders';
import Order from './Order';
import Details from './Details';
import NewOrder from './NewOrder';
import DeleteOrder from './DeleteOrder';
import Results from './Results';
import Actions from './Actions';

import './index.css';
import './Orders.css';

// Dates
var from = 1;
var today = new Date();
var yesterday = new Date();
today.setDate(today.getDate() + 1);
yesterday.setDate(yesterday.getDate() - from);

const statuses = [
  {value: 'open', label: 'Abierta', order: 2},
  {value: 'converted', label: 'Procesada', order: 1},
  {value: 'validated', label: 'Validada', order: 3},
  {value: 'consolidated', label: 'Consolidada', order: 4}
];

const initialFilters = {
  customer: '', 
  startDate: yesterday.toISOString(),
  endDate: today.toISOString(),
  status: { 
    open: true,
    converted: true,
    validated: true,
    consolidated: false
  }
};

export default function Orders(props) {

  const { orderId } = useParams();
  const { mobil } = useOutletContext();

  const [isLoading, setIsLoading] = useState(true);
  
  const [crm, setCrm] = useState([]);
  const [catalog, setCatalog] = useState([]);
  const [relation, setRelation] = useState([]);
  const [productOptions, setProductOptions] = useState([]);

  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selected, setSelected] = useState('');// o1619633028186
  const [validation, setValidation] = useState({
    isValidating: true, progress: 0
  });
  const [isConsolidating, setIsConsolidating] = useState(false);
  const [isResult, setIsResult] = useState(false);
  const [url, setUrl] = useState('');
  
  const [newOrder, setNewOrder] = useState(false);
  const [deleteOrder, setDeleteOrder] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  
  const [sort, setSort] = useState(null);
  const [status, setStatus] = useState({ status: 'validate', isActive: false, progress: 0 });
  const [isFilters, setIsFilters] = useState(false);
  const [filters, setFilters] = useState(initialFilters);

  const [arrowUp, setArrowUp] = useState(false);
  const [arrowDown, setArrowDown] = useState(true);
  
  const [subscription, setSubscription] = useState({ type: null, data: null });
  
  // Initial data
  React.useEffect(() => {
    let componentMounted = true;
    const fetchData = async () => {
      // Get catalog
      var newCatalog = await getCatalog();
      var newProductOptions = await getProductOptions(newCatalog);

      var newCrm = await getCrm();
      
      // Add default products to list
      var newRelation = {};
      newCatalog.map(x => {
        newRelation[cleanString(x.product)] = x.product;
        try {
          if ( !['', '-'].includes(x.dk) ) {
            x.dk.split(',').map(d => {
              newRelation[cleanString(d)] = x.product;
            })
          }
        } catch {
          console.log(x);
        }
      }); 

      // Get open orders
      var newOrders = await getOrders(filters);

      if ( componentMounted ) {
        setRelation(newRelation);
        setCatalog(newCatalog);
        setProductOptions(newProductOptions);
        setCrm(newCrm);
        
        if ( newOrders.length > 0 ) setOrders(newOrders);
        setIsLoading(false);
      }
    };
    
    fetchData();
    return () => componentMounted = false;
  }, []);

  // Update call to action
  React.useEffect(() => {
    handleReset();
  }, [orders]);

  // Subscriptions
  React.useEffect(() => {
    let componentMounted = true;
    
    const subscribeCreates = API.graphql({
      query: subscriptions.onCreateLaViaSana
    }).subscribe({
      next: ({ provider, value }) => {
        var data = value.data.onCreateLaViaSana;
        console.log('onCreate: ', data);
        if ( data !== null ) {
          setSubscription({ type: 'onCreate', data: data });
        }
      },
      error: error => console.warn(error)
    });

    const subscribeUpdates = API.graphql({
      query: subscriptions.onUpdateLaViaSana
    }).subscribe({
      next: ({ provider, value }) => {
        var data = value.data.onUpdateLaViaSana;
        console.log('onUpdate: ', data);
        if ( data !== null ) {
          setSubscription({ type: 'onUpdate', data: data });
        }
      },
      error: error => console.warn(error)
    });

    const subscribeDeletes = API.graphql({
      query: subscriptions.onDeleteLaViaSana
    }).subscribe({
      next: ({ provider, value }) => {
        var data = value.data.onDeleteLaViaSana;
        console.log('onDelete: ', data);
        if ( data !== null ) {
          setSubscription({ type: 'onDelete', data: data });
        }
      },
      error: error => console.warn(error)
    });

    return () => {
     componentMounted = false;
     subscribeCreates.unsubscribe();
     subscribeUpdates.unsubscribe();
     subscribeDeletes.unsubscribe();
    }
  }, []);

  React.useEffect(() => {
    const { type, data } = subscription;
    if ( data !== null ) {
      processSubscription(type, data);
      setSubscription({ type: null, data: null })
    }
  }, [subscription]);

  const processSubscription = (type, data) => {
    
    if ( data.sk === 'PROGRESS' ) {
      if ( data.dk === '100' ) {
        setStatus({ 
          status: data.id.slice(1),
          isActive: false,
          progress: parseInt(data.dk) 
        });
      } else if ( data.dk.slice(0,4) === 'http' ) {
        setUrl(data.dk);
        setStatus({ 
          status: data.id.slice(1),
          isActive: false,
          progress: 100
        });
        setIsResult(true);
      } else {
        setStatus({ 
          status: data.id.slice(1),
          isActive: true,
          progress: parseInt(data.dk) 
        });
      }

    } else if ( data.sk === 'ORDER' ) {
      
      if ( type === 'onCreate' ) {
        console.log('Adding ORDER: ', data.id, data);
        var newOrders = [ ...orders, data ];
      
      } else if ( type === 'onUpdate' ) {
        console.log('Update ORDER: ', data.id, data.dk);
        var newOrders = orders.map(o => {
          if ( o.id === data.id ) return data;
          return o;
        });
        // if ( order ) setOrder(data);
      
      } else if ( type === 'onDelete' ) {
        console.log('Delete ORDER: ', data.id, data);
        var newOrders = orders.filter(o => o.id !== data.id);
      }  
      
      console.log('UPDATED ORDERS: ', newOrders);
      setOrders(newOrders);
    
    } else {
      console.log('other subscription');
    }
  };

  // Functions
  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  };

  const cleanString = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/ +(?= )/g,'')
      .replace(/[,.]/g,'')
      .toLowerCase()
      .trim()
  };

  function formatDate(date) {
    
    const utcTimestamp = new Date(date).getTime();
    const localTimezoneOffset = new Date().getTimezoneOffset();
    const localTimestamp = utcTimestamp - localTimezoneOffset * 60000;
    const newDate = new Date(localTimestamp);

    var days = { 0: 'dom', 1: 'lun', 2: 'mar', 3: 'mié', 4: 'jue', 5: 'vie', 6: 'sáb' }

    var months = {
      0: 'enero', 1: 'febrero', 2: 'marzo', 3: 'abril',
      4: 'mayo', 5: 'junio', 6: 'julio', 7: 'agosto',
      8: 'septiembre', 9: 'octubre', 10: 'noviembre', 11: 'diciembre'
    }
    
    if (mobil) {
      return newDate.getDate()+' de '+
      months[newDate.getMonth()].slice(0,3) + '.'
    }
    return days[newDate.getDay()]+', '+
      newDate.getDate()+' de '+
      months[newDate.getMonth()]
      // newDate.getFullYear()
  };


  const getCatalog = async () => {
    try {
      const data = { // OPTIONAL
        headers: {}, // OPTIONAL
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      };
      const response = await API.post('laViaSanaREST', '/catalog', data);
      console.log('Catalog: ', response.data.data)
      return response.data.data;      

    } catch(error) {
      console.log('Error getting catalog, please contact support.', error);
      alert("Error getting catalog, please contact support.");
    };
  };

  const getCrm = async () => {
    try {
      const data = { // OPTIONAL
        headers: {}, // OPTIONAL
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      };
      const response = await API.post('laViaSanaREST', '/crm', data);
      console.log('CRM: ', response.data.data)
      return response.data.data;      

    } catch(error) {
      console.log('Error getting CRM, please contact support.', error);
      alert("Error getting CRM, please contact support.");
    };
  };

  const getCatalogAppSync = async () => {        
    try {
      var newCatalog = [];
      var { data: { GS1: data } } = await API.graphql({ 
        query: queries.listCatalog, 
        variables: { 
          sk: 'CATALOG',
          limit: 20
        }
      });
      newCatalog = newCatalog.concat(data.items);

      while ( data.nextToken ) {
        var { data: { GS1: data } } = await API.graphql({ 
          query: queries.listCatalog, 
          variables: { 
            sk: 'CATALOG',
            limit: 20,
            nextToken: data.nextToken
          }
        });
        newCatalog = newCatalog.concat(data.items);
      }

      return newCatalog;
    } catch (error) {
      console.log('error getting catalog: ', error);
    }
  };

  const getProductOptions = catalog => {
    // const newProductOptions = [];
    return catalog
      .filter(item => item.product !== '')
      .sort((a, b) => (a.product > b.product) ? 1 : -1)
      .map(item => {
        return {
          value: cleanString(item.product),
          label: item.product
        }
      });
  };

  const getCrmAppSync = async () => {        
    try {
      var newCRM = [];
      var { data: { GS1: data } } = await API.graphql({ 
        query: queries.listCRM, 
        variables: { 
          sk: 'CRM',
          limit: 20
        }
      });
      newCRM = newCRM.concat(data.items);

      while ( data.nextToken ) {
        var { data: { GS1: data } } = await API.graphql({ 
          query: queries.listCRM, 
          variables: { 
            sk: 'CRM',
            limit: 20,
            nextToken: data.nextToken
          }
        });
        newCRM = newCRM.concat(data.items);
      }

      return newCRM;
    } catch (error) {
      console.log('error getting CRM: ', error);
    }
  };

  const getOrders = async filters => {

    try {
      var newOrders = [];

      console.log('params filters: ', filters);
      let params = { 
        query: queries.listOrders, 
        variables: { 
          sk: 'ORDER',
          date: { between: [ filters.startDate, filters.endDate ]},
          sortDirection: 'DESC',
        }
      };
      if ( Object.values(filters.status).includes(true) ) {
        params.filter = { or: [] };
        statuses.forEach(s => {
          if ( filters.status[s.value] ) {
            params.filter.or.push({
              dk: { eq: s.value }
            })
          }
        })
      }
      console.log('params: ', params);

      // Query data
      var res = await API.graphql(params);
      res = res.data.ordersByDate;
      newOrders = newOrders.concat(res.items);
      console.log('orders res: ', newOrders);

      while ( res.nextToken ) {
        params.nextToken = res.nextToken;
        var res = await API.graphql(params);
        console.log('more orders res: ', newOrders);
        res = res.data.ordersByDate;
        newOrders = newOrders.concat(res.items);
      }

      newOrders = newOrders.sort((a, b) => (statuses.find(s => s.value === a.dk).order > statuses.find(s => s.value === b.dk).order) ? 1 : -1);
      console.log('ORDERS: ', newOrders);
      return newOrders;

    } catch (error) {
      console.log('Error listing orders: ', error);
    }
  };

  const getList = async id => {  
    try {
      console.log('get list: ', id);
      let newItems = [];
      let params = { 
        query: queries.gs1LIST, 
        variables: { 
          sk: 'ITEM',
          dk: { eq: id },
        }
      };
      console.log('get list req: ', params);
      let res = await API.graphql(params);
      console.log('get list res: ', res);
      let key = Object.keys(res.data)[0];
      res = res.data[key];
      newItems = newItems.concat(res.items);

      while ( res.nextToken ) {
        params.nextToken = res.nextToken;
        console.log('get token req: ', params);
        res = await API.graphql(params);
        console.log('get token res: ', res);
        res = res.data[key];
        newItems = newItems.concat(res.items);
      }
      console.log('list res: ', newItems);

      return newItems;

    } catch (error) {
      console.log('error: ', error);
    }
  };

  const handleReset = () => {
    if ( 
      orders.filter(o => o.dk === 'open').length > 0 
      || orders.filter(o => !filters.customer || filters.customer && handleSearch(o.customer, filters.customer)).filter(o => filters.status[o.dk]).length === 0
    ) {
      setStatus({ status: 'validate', isActive: false, progress: 0 });
    } else {
      setStatus({ status: 'consolidate', isActive: false, progress: 0 });
    }
  };

  const handleAddOrder = async e => {
    if ( e.target.name === 'newOrder' ) {
      setNewOrder(true);
    } else {
      console.log(e.target.name);
    }
  };

  
  const handleFilters = async e => {

    try {
      let newFilters = { ...filters };
      if ( e.target.name === 'status' ) {
        newFilters.status[e.target.value] = !newFilters.status[e.target.value];
      } else {
        newFilters[e.target.name] = e.target.value;
      }
      setFilters(newFilters);

      // if ( e.target.name.includes('Date') ) {
        setIsLoading(true);
        console.log('date filter: ', e.target.name, e.target.value);
        
        var newOrders = await getOrders(newFilters);
        setOrders(newOrders);
        console.log('filters applied: ', newOrders);
        
        setIsLoading(false);
      // } 

    } catch(e) {
      setIsLoading(false);
      console.log('Error updating filters: ', e);

    }
  };

  const handleSearch = (value, search) => {
    value = value.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase();
    search = search.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase();
    return value.includes(search);
  };

  const handleStatus = async e => {
    try {
      console.log('new status: ', e.target.name, e.target.value)
      var newValues = orders.find(o => o.id === e.target.name);
      newValues.dk = e.target.value;
      delete newValues.status_order;

      let res = await API.graphql({ 
        query: mutations.updateOrder, 
        variables: { input: newValues }
      });
      console.log('Order updated: ', res.data.updateLaViaSana);
    
    } catch(e) {
      alert("Error updating order status, please contact support.")
      console.log('Error updating order', e)
    };
  };

  const handleSort = e => {

    let newOrders = Object.assign([], orders);
    console.log('sort: ', e.target.value);
    let newSort = { value: e.target.value, ascending: false };
    if ( newSort.value === 'date' ) {
      if ( !sort || sort.value !== newSort.value ) {
        newOrders = newOrders.sort((a, b) => new Date(a.date) < new Date(b.date) ? 1 : -1);
      } else if ( sort.value === newSort.value && !sort.ascending ) {
        newSort.ascending = true;
        newOrders = newOrders.sort((a, b) => new Date(a.date) > new Date(b.date) ? 1 : -1);
      } else {
        newSort = null;
      }
    } else if ( newSort.value === 'name' ) {
      if ( !sort || sort.value !== newSort.value ) {
        newOrders = newOrders.sort((a, b) => a.customer.localeCompare(b.customer));
      } else if ( sort.value === newSort.value && !sort.ascending ) {
        newSort.ascending = true;
        newOrders = newOrders.sort((a, b) => b.customer.localeCompare(a.customer));
      } else {
        newSort = null;
      }
    } else if ( newSort.value === 'status' ) {
      if ( !sort || sort.value !== newSort.value ) {
        newOrders = newOrders
          .sort((a, b) => new Date(a.date) > new Date(b.date) ? 1 : -1)
          .sort((a, b) => a.dk.localeCompare(b.dk));
      } else if ( sort.value === newSort.value && !sort.ascending ) {
        newSort.ascending = true;
        newOrders = newOrders
          .sort((a, b) => new Date(a.date) > new Date(b.date) ? 1 : -1)
          .sort((a, b) => b.dk.localeCompare(a.dk));
      } else {
        newSort = null;
      }
    } 
    
    if ( !newSort ) {
      newOrders = newOrders
        .sort((a, b) => new Date(a.date) < new Date(b.date) ? 1 : -1)
        .sort((a, b) => (statuses.find(s => s.value === a.dk).order > statuses.find(s => s.value === b.dk).order) ? 1 : -1);
    }
    console.log('new sort: ', newSort);
    setSort(newSort);
    setOrders(newOrders);
  };


  const handleDeleteOrder = e => {
    var buttonName = e.target.name;
    var orderId = e.target.value;
    console.log(orderId);
    
    if ( buttonName === 'delete' ) {
      setSelected(orderId);
      setDeleteOrder(true);
    } else if ( buttonName === 'confirm') {
      setIsDeleting(true);
      
      // Delete items in BackEnd
      getList(selected)
        .then(oldList => {
          console.log(oldList);
          oldList.map(item => {
            API.graphql({ 
              query: mutations.deleteLaViaSana, 
              variables: { input: { 
                id: item.id,
                sk: item.sk,
              }}
            })
              .then(response => response.data.deleteLaViaSana)
              .then(responseData => console.log(responseData))
          })
          
          // Delete order in BackEnd
          API.graphql({ 
              query: mutations.deleteLaViaSana, 
              variables: { input: { 
                id: orderId,
                sk: 'ORDER'
              }}
            })
              .then(response => response.data.deleteLaViaSana)
              .then(responseData => console.log(responseData))
          
          // Update orders
          setOrders(orders.filter(o => o.id !== orderId));
          setIsDeleting(false);
          setSelected('');
          setDeleteOrder(false);
        })

    } else if ( buttonName === 'cancel') {
      setSelected('');
      setDeleteOrder(false);
    } else {
      console.log(buttonName, orderId);
    }
  };

  const handleValidation = async () => {
    console.log('VALIDATE');
    setStatus({ status: 'validate', isActive: true, progress: 0 });

    const payload = {
      body: {}, // replace this with attributes you need
      headers: {}, // OPTIONAL
    };

    return API.post('laViaSanaREST', '/validate', payload)
      .then(response => console.log('VALIDATE RES: ', response))
      .catch(error => console.log(error))   
      // .then(responseData => console.log('HANDLE VALIDATION RESULT: ', responseData.prev.result))
  };

  const handleConsolidation = async () => {
    setStatus({ status: 'consolidate', isActive: true, progress: 0 });

    const payload = {
      body: {}, // replace this with attributes you need
      headers: {}, // OPTIONAL
    };
    return API.post('laViaSanaREST', '/consolidate', payload)
      .then(response => console.log('CONSOLIDATE RES: ', response))
      .catch(error => console.log(error))   
      // .then(responseData => console.log('HANDLE VALIDATION RESULT: ', responseData.prev.result))
    
    // response = JSON.parse(response.data.laViaSanaChatbot)
    // var newUrl = response.prev.result;
    // console.log(newUrl);
  };

  const handleResults = async () => {
    // Open results URL
    window.open(url, "_blank");
    await handleCloseResults();
  };

  const handleCloseResults = async () => {
    var newOrders = await getOrders(filters);
    setOrders(newOrders);
    setIsResult(false);
  };


  // Components
  const OrdersColumns = () => {
    return <div className='orders-columns'>
      {/*<span>ID</span>*/}
      <div className='orders-column'>
        Fecha
        <button
          value='date'
          onClick={handleSort}
        >
          { sort && sort.value === 'date' && sort.ascending
            ? <>
                <i className='fas fa-sort' />
                <i className='fas fa-sort-up' style={{ color: '#C767E5' }} />
              </>
            : sort && sort.value === 'date' && !sort.ascending
            ? <>
                <i className='fas fa-sort'/>
                <i className='fas fa-sort-down' style={{ color: '#C767E5' }} />
              </>
            : <i className='fas fa-sort' />
          }
        </button>
      </div>
      <div className='orders-column'>
        Cliente
        <button
          value='name'
          onClick={handleSort}
        >
          { sort && sort.value === 'name' && sort.ascending
            ? <>
                <i className='fas fa-sort' />
                <i className='fas fa-sort-up' style={{ color: '#C767E5' }} />
              </>
            : sort && sort.value === 'name' && !sort.ascending
            ? <>
                <i className='fas fa-sort'/>
                <i className='fas fa-sort-down' style={{ color: '#C767E5' }} />
              </>
            : <i className='fas fa-sort' />
          }
        </button>
      </div>
      <div
        className='orders-column'
        style={{ justifyContent: 'center' }}
      >
        Status
        <button
          value='status'
          onClick={handleSort}
        >
          { sort && sort.value === 'status' && sort.ascending
            ? <>
                <i className='fas fa-sort'/>
                <i className='fas fa-sort-up' style={{ color: '#C767E5' }} />
              </>
            : sort && sort.value === 'status' && !sort.ascending
            ? <>
                <i className='fas fa-sort'/>
                <i className='fas fa-sort-down' style={{ color: '#C767E5' }} />
              </>
            : <i className='fas fa-sort' />
          }
        </button>
      </div>
      <div className='flex orders-add'>
        <button 
          className='button primary' 
          name='newOrder' 
          onClick={handleAddOrder}
        >
          { mobil 
            ? <i className='fas fa-plus' />
            : `+ Agregar` 
          }
        </button>
      </div>
    </div>
  };

  if ( isLoading ) {
    return <Container>
      <Loading className='flex'>
        <img src={process.env.PUBLIC_URL + 'gifs/fruits_gif5.gif'} className='loading'/>
        <h2>Cargando...</h2>
      </Loading>
    </Container>
  }

  return <Container>
    <Content>      
      <FiltersOrders 
        mobil={mobil}
        filters={filters}
        status={status}
        handleReset={handleReset}
        handleFilters={handleFilters}
        handleValidation={handleValidation}
        handleConsolidation={handleConsolidation}
      />

      {/*Orders*/}
      <OrdersTable>
        <OrdersColumns />
        { orders.length > 0 && <div className='orders-list'>
            { orders
              .filter(o => !filters.customer || filters.customer && handleSearch(o.customer, filters.customer))
              .filter(o => filters.status[o.dk])
              .map((x, i) => (
                <Details 
                  key={`order-details-${i}`} 
                  index={i} 
                  length={orders.length} 
                  values={x} 
                  mobil={mobil}
                  filters={filters}
                  formatDate={formatDate}
                  handleStatus={handleStatus}
                  deleteOrder={handleDeleteOrder}
                />
            ))}
        </div> }
      </OrdersTable>

      { mobil && <Actions 
        mobil={mobil} 
        status={status} 
        handleReset={handleReset}
        handleValidation={handleValidation}
        handleConsolidation={handleConsolidation}
      /> }
    </Content>

    {/*Order*/}
    <Order 
      mobil={mobil}
      catalog={catalog}
      productOptions={productOptions}
      handleStatus={handleStatus}
    />

    {/*New order*/}
    <NewOrder 
      open={newOrder}
      crm={crm}
      handleClose={() => setNewOrder(false)}
    /> 

    {/*Results*/}
    <Results 
      open={isResult}
      url={url}
      handleResults={handleResults} 
      handleClose={handleCloseResults} 
    />
    
    {/*Delete order*/}
    <DeleteOrder 
      open={deleteOrder}
      data={orders.find(o => o.id === selected)}
      selected={selected}
      formatDate={formatDate}
      isDeleting={isDeleting}
      handleDeleteOrder={handleDeleteOrder}
    />

  </Container>
};

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 80px;

  @media (min-width: 666px)  {
    height: calc(100vh - 140px);
    padding-top: 0;
    padding: 20px;
  }
`;
const Content = styled.div`
  width: 100%;
  height: 100%;

  @media (min-width: 666px)  {
    display: grid;
    grid-template-columns: 340px auto;
    grid-gap: 20px;
  }
`;
const OrdersTable = styled.div`
  position: relative;
  height: calc(100% - 60px);
  background-color: transparent;
  padding: 0px 16px;

  text-align: center;

  @media (min-width: 666px)  {
    position: relative;
    padding: 10px 20px;
    width: 100%;
    height: 100%;
    overflow: hidden;

    display: grid;
    grid-template-rows: 60px calc(100% - 60px);

    border: 2px solid #D0BDE2;
    border-radius: 25px;
    background-color: transparent;

    text-align: center;
  }
`;
const Loading = styled.div`
  height: 100%;
  flex-direction: column;

  & h2 {
    height: 50px;
    font-family: 'PT Sans', sans-serif;
  }
  & img {
    width: calc(100% - 40px);
    height: auto;
  }
  @media (min-width: 666px)  {
    & img {
      height: 300px;
      width: auto;
      object-fit: contain;
    }
  }
`;
