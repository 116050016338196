import React, { useState, useEffect, useRef } from 'react';

import { API } from "aws-amplify";
import * as mutations from '../graphql/_mutations';
import * as queries from '../graphql/_queries';

import './SelectProductAdd.css';

function cleanString(str) {
  if ( str ) {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .trim()
  }
  return false
}

function SelectProductAdd(props) {
  
  const { vendor } = props;

  const [showList, setShowList] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [isFocus, setIsFocus] = useState(false);
  
  const menuRef = useRef(null);
  
  function useOutside(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // console.log(ref.current, event.target);
          setShowList(false);
        }
      }
      
      function onKeyDown(e) {
        if (e.key === 'Escape') {
          setShowList(false);
        } else if ( e.key === 'ArrowUp' ) {
          if ( showList && cursor > 0 ) {
            setCursor(cursor - 1)
          }
        } else if ( e.key === 'ArrowDown' ) {
          if ( showList && 
            cursor < props.options.filter(item => item.value
              .includes(
                cleanString(props.value)
              )
            ).length
          ) {
            setCursor(cursor + 1)
          } else if ( isFocus ) {
            setShowList(true);
          }
        } else if ( e.key === 'Enter' ) {
            if ( showList ) {
              var newValue = props.options
                .filter(item => item.value
                  .includes(cleanString(props.value)))[cursor]
              
              props.onChange(newValue.label)
              setShowList(false);
            }
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      window.addEventListener('keydown', onKeyDown);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        window.removeEventListener('keydown', onKeyDown);
      };
    }, [ref, showList, isFocus, cursor, props]);
  }
  useOutside(menuRef);

  const handleChange = async e => {
    props.onChange(e.target.value);
    setCursor(0);
    if ( vendor === 'laviasana' ) {
      setShowList(true);
    }
  }

  const handleSelect = async e => {
    props.onChange(e.target.value);
    setShowList(false);
  }  

  return <div ref={menuRef} className='select-p-add'>  
    
    { vendor === 'notas'
      ? <textarea 
          className='input textarea'
          name='product'
          value={props.value}
          onChange={handleChange}
          placeholder='Escribe notas'
        />
      : <input 
          type='text'
          className='input'
          autoComplete='off'
          name='product'
          value={props.value}
          onChange={handleChange}
          onClick={() => {
            var numOptions = props.options
              .filter(item => item.value.includes(cleanString(props.value)))
              .length

            if ( vendor === 'laviasana' && numOptions > 0 ) {
              setShowList(!showList);
            }
            setIsFocus(true);
          }}
          placeholder='Escribe el nombre del producto'
        />
    }

    { showList && <div className='select-p-add-items'>
      { props.options
          .filter(item => item.value.includes(cleanString(props.value)))
          .map((item, i) => (
              <button 
                index={i}
                key={i}
                className={item.label === props.value
                  ? 'select-p-add-item active' 
                  : 'select-p-add-item'
                }
                name={props.name}
                value={item.label}
                style={{ backgroundColor: i === cursor ? '#D0BDE2' : '' }}
                onClick={handleSelect}
                onMouseEnter={e => setCursor(
                  parseInt(e.target.getAttribute('index')) 
                )}
                onMouseLeave={e => setCursor(null)}
              >
                {item.label}
              </button> ))
      }
    </div> }
  </div>
}

export default SelectProductAdd;
