import React, { useState, useEffect, useRef } from 'react';

import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';

export default function DeleteOrder(props) {

  const { open, data, selected, isDeleting } = props;

  return <AnimatePresence>
    { open && <Container
      className='flex'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { delay: 0.3 } }}
    >
      <Content
        className='bg'
        initial={{ y: -window.innerHeight }}
        animate={{ y: 0, transition: { delay: 0.3 } }}
        exit={{ y: -window.innerHeight }}
      >
        <h2>Eliminar pedido</h2>

        <p>
          ¿Estas seguro de eliminar la orden de&nbsp;
          <span>{data.customer}</span>
           &nbsp;del&nbsp;
          <span>{props.formatDate(data.date)}</span>
          ?
        </p>
        
        <Actions>
          <button 
            className='button secondary'
            name='cancel'
            onClick={props.handleDeleteOrder}
          >
            CANCELAR
          </button>

          <button 
            className='button primary'
            name='confirm'
            value={selected}
            onClick={props.handleDeleteOrder}
            disabled={isDeleting}
          >
            { isDeleting
              ? 'Eliminando...'
              : 'ELIMINAR'
            }
          </button>
        </Actions>
      </Content>
    </Container> }
  </AnimatePresence>
};

const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 777;
  height: 100%;
  width: 100%;

  background-color: rgba(0,0,0,0.5);
  backdrop-filter: blur(8px);

  @media (min-width: 666px)  {
  }
`;
const Content = styled(motion.div)`
  position: relative;
  width: calc(100% - 32px);
  padding: 16px;

  border: 2px solid #D0BDE2;
  border-radius: 16px;

  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2); 
  
  & p {
    margin-top: 8px;
    font-size: 1rem;

    & span {
      font-weight: bold;   
    }
  }

  @media (min-width: 666px)  {
    width: 40vw;
  }
`;

const Actions = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  grid-gap: 8px;

  & button {
    height: 40px;
    padding: 0px 16px;
  }
`;
